import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Sequence from "./Sequence/Sequence";
import Schedules from "./Schedules";
import Options from "./Options/Options";
import Leads from "./Leads/Leads";
import Analytics from "./Analytics";

// const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles({
    customStyleOnTab: {
        color: "#4F4F4F",
        fontWeight: 700,
    },
    customStyleOnActiveTab: {
        background: "none",
    },
    activeTab: {
        fontWeight: 700,
        backgroundColor: "#fff",
        borderRadius: "6px",
        color: "#481BEB",
    },
});

const CampaignFunctions = (props) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const saveErrorHandler = () => {
        props.saveErrorHandler();
    };
    return (
        <>
            <Box sx={{ backgroundColor: "#f7f7f9", borderRadius: "10px" }}>
                <Tabs
                    value={tabValue}
                    variant="fullWidth"
                    onChange={handleTabChange}
                    classes={{ indicator: classes.customStyleOnActiveTab }}
                    indicatorColor={"#607d8b"}
                    centered
                >
                    <Tab
                        disableRipple
                        sx={{ padding: "10px 10px" }}
                        label={
                            <div
                                style={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "52px",
                                }}
                                className={tabValue === 0 ? classes.activeTab : classes.customStyleOnTab}
                            >
                                <span>Leads</span>
                            </div>
                        }
                    />
                    <Tab
                        disableRipple
                        sx={{ padding: "10px 10px" }}
                        label={
                            <div
                                style={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "52px",
                                }}
                                className={tabValue === 1 ? classes.activeTab : classes.customStyleOnTab}
                            >
                                <span>Sequences</span>
                            </div>
                        }
                    />
                    <Tab
                        disableRipple
                        sx={{ padding: "10px 10px" }}
                        label={
                            <div
                                style={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "52px",
                                }}
                                className={tabValue === 2 ? classes.activeTab : classes.customStyleOnTab}
                            >
                                <span>Schedules</span>
                            </div>
                        }
                    />
                    <Tab
                        disableRipple
                        sx={{ padding: "10px 10px" }}
                        label={
                            <div
                                style={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "52px",
                                }}
                                className={tabValue === 3 ? classes.activeTab : classes.customStyleOnTab}
                            >
                                <span>Options</span>
                            </div>
                        }
                    />
                    <Tab
                        disableRipple
                        sx={{ padding: "10px 10px" }}
                        label={
                            <div
                                style={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "52px",
                                }}
                                className={tabValue === 4 ? classes.activeTab : classes.customStyleOnTab}
                            >
                                <span>Analytics</span>
                            </div>
                        }
                    />
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <Box sx={{ pt: 2 }}>
                    <Leads id={props.id} />
                </Box>
            )}
            {tabValue === 1 && (
                <Box sx={{ minHeight: "400px", p: 0, mt: 3 }}>
                    <Sequence id={props.id} saveErrorHandler={saveErrorHandler} />
                </Box>
            )}
            {tabValue === 2 && (
                <Box
                    sx={{
                        minHeight: "400px",
                        p: 5,
                        mt: 3,
                        boxShadow: "0 2px 11px 0px #0000001a",
                        borderRadius: "40px",
                    }}
                >
                    <Schedules id={props.id} saveErrorHandler={saveErrorHandler} />
                </Box>
            )}
            {tabValue === 3 && (
                <Box
                    sx={{
                        minHeight: "400px",
                        p: 5,
                        mt: 3,
                        boxShadow: "0 2px 11px 0px #0000001a",
                        borderRadius: "40px",
                    }}
                >
                    <Options id={props.id} saveErrorHandler={saveErrorHandler} />
                </Box>
            )}
            {tabValue === 4 && (
                <Box sx={{ minHeight: "400px", p: 5, mt: 3 }}>
                    <Analytics id={props.id} />
                </Box>
            )}
        </>
    );
};

export default CampaignFunctions;
