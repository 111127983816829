import { styled } from "@mui/material/styles";
import {
    Typography,
    TextField,
    Button,
    createTheme,
    TableContainer,
    TableCell,
    TableRow,
    Select,
    MenuItem,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    paddingBottom: "1px",
    fontWeight: "600",
    fontFamily: "'Inter', sans-serif",
}));

export const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    paddingBottom: "1px",
    fontWeight: "700",
    fontFamily: "'HK Nova', sans-serif",
    color: "#272727",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    fontSize: "14px",
    backgroundColor: "#f7f7f9",
    borderRadius: "8px",
    color: "#9491AD",
    marginBottom: theme.spacing(3),
    "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
}));
export const StyledSelectOpt = styled(Select)(({ theme }) => ({
    fontFamily: "'Inter', sans-serif",
    fontWeight: "400",
    fontSize: "14px",
    backgroundColor: "#f7f7f9",
    borderRadius: "8px",
    color: "#9491AD",
    "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
}));

export const StyledButtonOutlined = styled(Button)(({ theme }) => ({
    paddingY: theme.spacing(1),
    color: "#481BEB",
    borderColor: "#E7E7E7",
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    "&:hover": {
        borderColor: "#E7E7E7",
    },
}));

export const colorTheme = createTheme({
    palette: {
        success: {
            main: "#38C68B",
        },
        primary: {
            main: "#481BEB",
        },
        error: {
            main: "#EF6D6D",
        },
    },
});

export const StyledTableContainer = styled(TableContainer)({
    borderRadius: "10px",

    // Style the scroll bar
    "&::-webkit-scrollbar": {
        width: "5px",
        height: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D0CED5",
        borderRadius: "6px",
    },
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#fff",
        fontSize: "12px",
        fontWeight: "700",
        color: "#989898",
        fontFamily: "'Inter', sans-serif",
        border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: "500",
        border: "none",
        color: "#141B3A",
        fontFamily: "'HK Nova', sans-serif",
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0 2px 11px 0px #0000001a",
    marginBottom: "10px",
    "&:last-child td, &:last-child th": {
        // border: 0,
    },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    borderBottom: "1px solid #E7E7E7",
    "&:hover": {
        borderLeft: "3px solid #7953FF",
    },
}));
