import React from "react";
import "./TermsAndConditions.css";
import { Paper, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import RingLogo from "../../img/svg/ring.png";

const privacyAndpolicy = () => {
    return (
        <div>
            <div id="logoContainer">
                <img src={xCampaignLogo} alt="logo" id="RingLogo1"></img>
            </div>
            <div id="headerPrivacy">
                <div id="headertext">
                    <Link exact to="/register" id="linkcomponent">
                        Home &gt;{" "}
                    </Link>
                    <span>Privacy Policy</span>
                </div>
            </div>
            <div id="RingLogo1">
                <img src={RingLogo} alt="" id="ringlogo1"></img>
            </div>
            <div id="terms-container">
                <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Privacy Policy</h1>
                {/* <div id="topHead">Terms and Conditions</div> */}
                <Paper elevation={3} sx={{ padding: "20px" }}>
                    <section id="terms-section">
                        <h2 id="heading2">About this policy</h2>
                        <p id="paraTag">
                            Your privacy is important to us, and we are committed to protect your privacy. We believe it
                            is important for you to know what personal data we at XGrowth LLC ("XGrowth or “we”) collect
                            from you, why we collect it, how we use it and what rights you might be entitled to as a
                            data subject or consumer.
                        </p>
                        <p id="paraTag">
                            It is important that you read this policy, together with any additional and more specific
                            information we may provide to you on various occasions when we are collecting or processing
                            personal data. Your use of our sites or products or services indicates you agree to our
                            collection, usage, and disclosure of your information as described in this Privacy Policy.
                        </p>
                    </section>

                    <section id="terms-section">
                        <h2 id="heading2">Information we collect</h2>
                        <p id="paraTag">We collect only the information needed for legitimate business purpose.</p>

                        <p id="paraTag">
                            You may need to provide some personal information such as your full name, email ID, contact
                            details, designation etc. when you sign up for an account, register for an event, ask for
                            customer support, wish to buy our product or services.
                        </p>

                        <p id="paraTag">
                            If you are availing our products or services; we may collect following personal information
                            from you or through your employer - full name, email id, contact details, address,
                            designation, fax etc.
                        </p>
                        <p id="paraTag">
                            We may collect personal information directly from publicly available databases, social media
                            sites or in collaboration with our partners from time to time. You may also need to provide
                            some personal information if you wish to explore employment opportunities with us.
                        </p>
                    </section>

                    <section id="terms-section">
                        <h2 id="heading2">What we do with your personal information</h2>
                        <p id="paraTag">
                            We do not share or sell your personal information to anyone. We use personal information
                            solely for the purpose of interacting with you and to enable you to avail our product or
                            services. We use personal information only for the specific reason for which it is provided.
                        </p>

                        <p id="paraTag">Some personal information is required:</p>
                        <p id="paraTag">
                            <ul>
                                <li>To manage, administer and fulfill the obligations under contracts</li>
                                <li>
                                    {" "}
                                    To promote the XGrowth brand, products, initiatives and values with marketing
                                    communications.
                                </li>
                            </ul>
                        </p>
                        <p id="paraTag">
                            We may send you marketing communications through various channels, such as email or phone;
                            to do so, we might use your name, email id, and contact details. You can opt-out of
                            receiving such communications at the time of first contact over the email or any time by
                            writing to us at business@xemailverify.com
                        </p>
                        <p id="paraTag">
                            We will only use your personal data for the purposes for which we collect it, we reasonably
                            consider that we need to use it for another reason that is compatible with the original
                            purpose and applicable local law. Any exceptions will be brought to your notice and legal
                            basis for the same will be explained.
                        </p>
                    </section>
                    <section id="terms-section">
                        <h2 id="heading2">How long will we keep your personal information?</h2>
                        <p id="paraTag">
                            We will only retain your personal data for as long as necessary to fulfill the purposes we
                            collected it for, including for the purposes of satisfying any legal, accounting, or
                            reporting requirements.
                        </p>
                        <p id="paraTag">
                            In some circumstances we may anonymize your personal data so that it can no longer be
                            directly associated with you.
                        </p>
                    </section>
                    <section id="terms-section">
                        <h2 id="heading2">How will we keep your information safe?</h2>
                        <p id="paraTag">
                            We are fully committed to information security and compliance with applicable regulation. We
                            have implemented strong security controls for the protection of data. We have designed and
                            implemented information security program in line with International Organization for
                            Standardization (ISO) 27001:2013 standard and Service Organization Controls 2 (SOC). We have
                            put in place appropriate measures to comply with the European Union (EU) General Data
                            Protection Regulation (GDPR).
                        </p>
                        <p id="paraTag">
                            Though we take reasonable measures to protect our assets against unauthorized access or
                            attack; Internet inherently is not fully secure. While we work towards and strive to protect
                            your personal information/privacy, we would like you to take note of inherent Internet risks
                            associated with data transfer and processing. You also need to ensure that your User ID,
                            Password etc. are not disclosed with anyone and your systems are safe for usage.
                        </p>
                        <p id="paraTag">
                            At any point, if you suspect any security issues or incidents, or you receive any suspicious
                            mail from someone holding themselves out to be a XGrowth employee or from a fake website
                            claiming to be affiliated with XGrowth, you may reach out to us at business@xemailverify.com
                        </p>
                    </section>
                    <section id="terms-section">
                        <h2 id="heading2">How are cookies used?</h2>
                        <p id="paraTag">
                            Cookie is a small piece of data stored on the user's computer by the web browser while
                            browsing a website. We use cookies to improve the quality of our site and service, and to
                            try and make your browsing experience meaningful. Cookies may be used to track how you
                            interact with our sites and to analyze trends. The types of data collected may include IP
                            addresses, cookies identifiers, site activities etc.
                        </p>
                        <p id="paraTag">
                            We use first-party and third-party cookies for several purposes. The first-party cookies are
                            mostly necessary for the website to function the right way.
                        </p>
                        <p id="paraTag">
                            The third-party cookies used on our websites are used mainly for understanding how the
                            website performs, how you interact with our website, keeping our services secure, providing
                            information that is relevant to you, and all in all providing you with a better and improved
                            user experience.
                        </p>
                        <p id="paraTag">
                            You can control the use of cookies, but if you choose to disable cookies, it may limit your
                            use of certain features or functions on our website or service.
                        </p>
                    </section>

                    <section id="terms-section">
                        <h2 id="heading2">How to contact us?</h2>
                        <p id="paraTag">
                            If you have a privacy concern, complaint, or a question regarding this privacy statement,
                            please let us know by sending an email to business@xemailverify.com
                        </p>
                        <p id="paraTag">
                            We value your trust and will take the appropriate measures to ensure that we fulfill your
                            request.
                        </p>
                        <h2 id="heading2">You have certain choices</h2>
                        <p id="paraTag">
                            You may contact us at business@xemailverify.com on various matters pertaining to your
                            personal information such as –
                        </p>
                        <p id="paraTag">
                            <ul>
                                <li>request access</li>
                                <li>request rectification</li>
                                <li>request deletion</li>
                                <li>request data portability</li>
                                <li>
                                    request to opt out from receiving email newsletters, alerts or other marketing
                                    emails
                                </li>
                            </ul>
                        </p>
                        <p id="paraTag">
                            Upon receiving your request, we will make every effort to fulfill your request; if it is not
                            otherwise required to be treated differently by law or for legitimate business purposes. You
                            must identify yourself prior to making a request; we may not be able to process your request
                            if it is deemed unreasonable or inappropriate.
                        </p>
                        <p id="paraTag">
                            We will respond to your queries within a reasonable timeframe. Please note that we may need
                            to maintain residual copies even after your information gets deleted from the active
                            environment (e.g., backup copies or to ensure we don’t contact you if you have opted out).
                        </p>
                    </section>

                    <section id="terms-section">
                        <h2 id="heading2">Legal Notice</h2>
                        <p id="paraTag">
                            XGrowth may need to disclose personal information to legal authorities for compliance, fraud
                            investigation, statutory purposes or for other legal activities as per the local laws and
                            government request.
                        </p>
                    </section>
                    <section id="terms-section">
                        <h2 id="heading2">Changes to this privacy statement</h2>
                        <p id="paraTag">
                            We reserve the right to update this privacy notice at any time, and we will provide you with
                            a new privacy notice when we make any substantial updates. We may also notify you in other
                            ways from time to time about the processing of your personal data.
                        </p>
                    </section>

                    <h2 id="heading2">&#169; 2023 XGrowth Tech Pvt. Ltd. All rights reserved.</h2>
                </Paper>
            </div>
            <div id="RingLogo1">
                <img src={RingLogo} alt="" id="ringlogo2"></img>
            </div>
            <div id="footerContainer">
                <footer style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <hr></hr>

                    <Box sx={{ width: "50%" }}>
                        <Link
                            exact
                            to="/privacyAndpolicy"
                            style={{ fontSize: "20px", color: "white", padding: "20px" }}
                            id="link"
                        >
                            Privacy Policy
                        </Link>
                        <Link exact to="/termsAndconditons" style={{ fontSize: "20px", color: "white" }} id="link">
                            Terms & Conditions
                        </Link>
                    </Box>

                    <Box sx={{ width: "50%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <Typography> Copyright &#169; 2023 XGrowth Tech Pvt. Ltd.</Typography>
                    </Box>
                </footer>
            </div>
        </div>
    );
};

export default privacyAndpolicy;
