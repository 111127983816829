import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    Stack,
    Table,
    Typography,
    Avatar,
    TableBody,
    IconButton,
    Paper,
    InputBase,
    TableHead,
    TableRow,
    Chip,
    Grid,
    FormControl,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Snackbar,
    Alert,
    Card,
    Container,
    CircularProgress,
    MenuItem,
    InputLabel,
    Select,
    Checkbox,
    CardContent,
    MenuList,
} from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import ErrorIcon from "@mui/icons-material/Error";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyIcon from "@mui/icons-material/Reply";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoIcon from "@mui/icons-material/Info";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import google from "../../img/icons/google.png";
import outlook from "../../img/icons/outlook.png";
import imap_smtp from "../../img/icons/imap_smtp.png";
import filterIcon from "../../img/icons/filter.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
    StyledTableCell,
    StyledTableRow,
    StyledButtonOutlined,
    StyledTableContainer,
    StyledTextField,
    StyledTypography,
    colorTheme,
} from "../../stylings/styles";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useSearchParams } from "react-router-dom";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink, CSVDownload } from "react-csv";
import Pagination from "@mui/material/Pagination";
import { tooltipClasses } from "@mui/material/Tooltip";
import photo from "../../img/admin.pdf";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import DeleteIcon from "@mui/icons-material/Delete";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const REACT_APP_GOOGLEAUTH_KEY = process.env.REACT_APP_GOOGLEAUTH_KEY;

const StyledBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#2196F3",
    },
}));
const StyledButton = withStyles({
    root: {
        backgroundColor: "#fff",
        border: "1px solid #E7E7E7",
        "&:hover": {
            backgroundColor: "#fff",
            boxShadow: "0px 8px 40px rgba(4, 0, 49, 0.16)",
        },
    },
})(Button);

const IconWithText = ({ text, color, figure }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
        {text === "Health" && <StopRoundedIcon style={{ color }} />}
        <Typography
            sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#181818",
            }}
        >
            {text === "Health" ? "Good" : figure}
        </Typography>
    </div>
);

// const mdTheme = createTheme({
//   palette: {
//     success: {
//       main: "#38C68B",
//     },
//   },
// });

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#004AAD",
//     color: '#fff',
//     fontSize: "12px",
//     fontWeight: "700"
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   // hide last border

//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

const EmailAccounts = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [visible, setVisible] = useState(false);
    const [filtervalue, setFiltervalue] = useState("All");
    const [searchValue, setSearchValue] = React.useState("");
    const [filterValue, setFilterValue] = React.useState([]);
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [createdAtDate, setCreatedAtDate] = React.useState("dd-mm-yyyy");
    const [settingAnalyticsData, setSettingAnalyticsData] = React.useState(null);
    const [settingDrawer, setSettingDrawer] = React.useState(false);
    const [settingDrawerData, setSettingDrawerData] = React.useState(null);
    const [settingProfileData, setSettingProfileData] = React.useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [addNewOpen, setAddNewOpen] = React.useState(false);
    const [googleOauth, setGoogleOauth] = React.useState(false);
    const [imap, setImap] = React.useState(false);
    const [imapSteps, setImapSteps] = React.useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [smtpHost, setSmtpHost] = React.useState("");
    const [smtpPort, setSmtpPort] = React.useState(587);
    const [imapHost, setImapHost] = React.useState("");
    const [imapPort, setImapPort] = React.useState(993);
    const [newDataAdd, setNewDataAdd] = React.useState(false);
    const [smtpUsername, setSmtpUsername] = React.useState("");
    const [smtpPassword, setSmtpPassword] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(state === null ? false : state.status !== 200);
    const [espVisibility, setEspVisibility] = useState({});
    const [maxEspSelection, setMaxEspSelection] = useState(null);

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(state === null ? false : state.status === 200);

    const [homeLoading, setHomeLoading] = React.useState(false);
    const [change, setChange] = React.useState(false);
    const [cronjobToggle, setCronjobToggle] = React.useState(false);
    const [addNewErrorMessage, setAddNewErrorMessage] = React.useState(state === null ? "" : state.msg);
    const [addNewSuccessMessage, setAddNewSuccessMessage] = React.useState(state === null ? "" : state.msg);
    const [googleOauthSteps, setGoogleOauthSteps] = React.useState(0);
    const [googleConnectTypes, setGoogleConnectTypes] = React.useState("oAuth");
    const [imapsmptConnectTypes, setImapSmptConnectTypes] = React.useState("ImapSmpt");
    const [uploadCsvFile, setUploadCsvFile] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [removeAccountDialog, setRemoveAccountDialog] = React.useState(false);
    const [accounctDeleteSuccess, setAccounctDeleteSuccess] = React.useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [bulkData, setbulkData] = useState([]);
    const [showDeltebutton, setDeleteButton] = useState(false);
    const [deletebulkDialougeOpen, setdbulkCampaignDialougeOpen] = React.useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [updateDialog, setUpdateDialog] = React.useState(false);
    const [IMAPDialog, setIMAPDialog] = React.useState(false);
    const [parsedData, setParsedData] = useState([]);
    const [csvfileLoader, setCsvFileLoader] = React.useState(false);

    const [editDialougeOpen, setEditDialougeOpen] = useState(false);
    const [startVol, setStartVol] = useState("");
    const [endVol, setEndVol] = useState("");
    const [replyPercent, setReplyPercent] = useState("");
    const [incPerDay, setIncPerDay] = useState("");
    const [maxLimit, setMaxLimit] = useState("");
    const [EditButtonLoading, setEditButtonLoading] = useState(false);
    const [SMTPButtonLoading, setSMTPButtonLoading] = useState(false);
    const [IMAPButtonLoading, setIMAPButtonLoading] = useState(false);

    const [senderLimit, setsenderLimit] = useState(null);
    const [senderLength, setsenderLength] = useState(null);
    const [mailLimit, setmailLimit] = useState(null);
    const [remainLimit, setremainLimit] = useState(null);

    const [totalpage, setTotalpage] = useState(null);
    const [currentpage, setcurrentpage] = useState(1);
    const [recordlimit, setrecordlimit] = useState(20);
    const [Paginationvar, setPaginationvar] = useState(false);
    const [espValue, setEspValue] = useState("");

    let [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("code");
    const scope = searchParams.get("scope");
    const user = jwt(cookie.get("token"));
    const [fileUpload, setFileUpload] = useState(null);
    const [CsvRecords, setCsvRecords] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [storeFileName, setStoreFileName] = useState(null);
    const [flag, setFlag] = React.useState(false);
    const [isVolumeDisabled, setIsVolumeDisabled] = useState(null);
    const [NewOpen, setNewOpen] = React.useState(false);
    const [professionalopen, setProfessionalOpen] = React.useState(false);
    const [userclient, setUserClient] = React.useState(false);
    const [clientId, setClientId] = React.useState("");
    const [tenantId, setTenantId] = React.useState("");
    const [clientSecret, setClientSecret] = React.useState("");
    const [searchclients, setSearchClients] = React.useState(false);
    const [SearchClient, setSearchClient] = React.useState("");
    const [sendmail, setSendMail] = React.useState([]);
    const [chooseClient, setchooseClient] = useState([]);
    const [sendloader, setSendloader] = useState(false);
    const [mircosoftloader, setMircosoftLoader] = useState(false);
    const [storeloader, setStoreLoader] = useState(false);
    const [textfielddisabled, setTextFieldDisabled] = useState(false);

    const [usertype, setUsertype] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 20;
    const [selectedValues, setSelectedValues] = useState([]);
    const [clienamebox, setClientNameBox] = React.useState(false);
    const [clienamebox1, setClientNameBox1] = React.useState(false);
    const [clientname, setClientName] = React.useState("");
    const [allClients, setAllClients] = React.useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [isSecretKeyModified, setIsSecretKeyModified] = useState(false);
    const [isClientIdModified, setIsClientIdModified] = useState(false);
    const [isTenantIdModified, setIsTenantIdModified] = useState(false);
    const [uncheckPopUp, setUncheckPopUp] = useState(false);
    const [deleteM365Loader, setDeleteM365Loader] = useState(false);

    const handleClientSecretChange = (e) => {
        setClientSecret(e.target.value);
        setIsSecretKeyModified(true);
    };

    const handleUpdateSecretKey = async () => {
        console.log("Updating secret key with:", clientSecret);

        if (!clientId || !tenantId || !clientSecret) {
            setErrorSnackbarOpen(true);
            seterrorMsg("All fields are required.");
            return;
        }

        setSendloader(true);

        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/api/m365-SecreKeyUpdate`, {
                headers: { Accept: "application/json" },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    clientId,
                    tenantId,
                    clientSecret,
                }),
            });

            const data = await response.json();
            setSendloader(false);

            if (response.ok) {
                setSuccessSnackbarOpen(true);
                setsuccessMsg(data.message || "Data updated successfully.");
            } else {
                setErrorSnackbarOpen(true);
                seterrorMsg(data.message || "An error occurred while processing the request.");
            }
        } catch (error) {
            setSendloader(false);
            setErrorSnackbarOpen(true);
            seterrorMsg(error.message || "An error occurred while connecting to the server.");
            setIsSecretKeyModified(false);
        }

        setIsSecretKeyModified(false); // Reset after update
    };

    const openDeleteConfirmation = (client) => {
        console.log(client, "comes inside the open box funciton...");
        setConfirmDelete(true);
        setClientToDelete(client);
    };

    const closeDeleteConfirmation = () => {
        setClientToDelete(null);
        setConfirmDelete(false);
    };

    const confirmDeleteClient = () => {
        if (clientToDelete) {
            handleDeleteClient(clientToDelete);
            closeDeleteConfirmation();
        }
    };

    const handleDeleteClient = async () => {
        console.log(clientToDelete, "coming inside the function to delete.....");
        setDeleteM365Loader(true);
        try {
            const response = await fetch(
                `${REACT_APP_SERVER_URL}/api/m365-clientDelete?clientName=${clientToDelete.name}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    credentials: "include",
                    method: "GET",
                }
            );

            if (!response.ok) {
                const errorMessage = await response.json();
                console.error("Error deleting client:", errorMessage);
                setErrorSnackbarOpen(true);
                seterrorMsg(errorMessage.message || "An error occurred while processing the request.");
            }

            const data = await response.json();
            console.log("Client deleted successfully:", data);
            setSuccessSnackbarOpen(true);
            setsuccessMsg(data.message || "Data updated successfully.");
        } catch (error) {
            console.error("Fetch error:", error);
            setErrorSnackbarOpen(true);
            seterrorMsg(error.message || "An error occurred while processing the request.");
        } finally {
            setDeleteM365Loader(false);
        }
    };

    const handleAddNewEmailClick = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmProceed = () => {
        storeSenders();
        setConfirmDialogOpen(false);
    };

    function handleClientNameChange() {
        setUserClient(true);
    }

    // const handleClientNameChange = () => {
    //     fetch(`${REACT_APP_SERVER_URL}/createClients`, {
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/x-www-form-urlencoded",
    //         },
    //         credentials: "include",
    //         method: "POST",
    //         body: new URLSearchParams({
    //             name: clientname,
    //         }),
    //     })
    //         .then((response) => {
    //             return response.json().then((data) => ({ status: response.status, data }));
    //         })
    //         .then(({ status, data }) => {
    //             console.log(data);
    //             if (data.success) {
    //                 // fetchMircosoft();
    //                 setUserClient(true);
    //                 setSuccessSnackbarOpen(true);
    //                 setsuccessMsg("Created Successfully");
    //             } else if (status === 400) {
    //                 setErrorSnackbarOpen(true);
    //                 seterrorMsg(data.message || "Error occurred while creating client");
    //             } else {
    //                 setErrorSnackbarOpen(true);
    //                 seterrorMsg("An unexpected error occurred");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error occurred while creating client:", error);
    //             setErrorSnackbarOpen(true);
    //             seterrorMsg("An unexpected error occurred");
    //         });
    // };

    function ClientName() {
        fetch(`${REACT_APP_SERVER_URL}/fetchClients`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json().then((data) => ({ status: response.status, data }));
            })
            .then(({ status, data }) => {
                setProfessionalOpen(false);
                if (status === 200 && data.success === true) {
                    setAllClients(data.data);
                    setClientNameBox(false);
                    setClientNameBox1(true);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Fetched Successfully");
                } else if (status === 400) {
                    setClientNameBox(true);
                    setClientNameBox1(false);
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.message || "No data found");
                } else {
                    setErrorSnackbarOpen(true);
                    seterrorMsg("An unexpected error occurred");
                }
            })
            .catch((error) => {
                console.error("Error occurred while creating client:", error);
                setErrorSnackbarOpen(true);
                seterrorMsg("An unexpected error occurred");
            });
    }

    function handleClientName() {
        setClientId("");
        setClientSecret("");
        setTenantId("");
        setTextFieldDisabled(false);
        setClientNameBox(true);
        setClientNameBox1(false);
    }

    function handleAllClientBack() {
        setProfessionalOpen(true);
        setClientNameBox1(false);
    }

    function handleClientBack() {
        setProfessionalOpen(true);
        setClientNameBox(false);
    }

    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        })
    );

    React.useEffect(() => {
        setHomeLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/warmuptest?App_type=Xwarmup&page=${currentpage}&limit=${recordlimit}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                setHomeLoading(false);
                setPaginationvar(false);

                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                    throw new Error("Token expired");
                } else {
                    console.log("some error");
                    throw new Error("Some error occurred");
                }
            })
            .then((data) => {
                setsenderLength(data.warmup_analytics.length);
                setTableData(data);
                setTotalpage(data.totalPages);
                setsenderLimit(data.stripe_customer.plan_details.metadata.mailboxes);
                setmailLimit(data.stripe_customer.plan_details.metadata.max_warmup_email);
                setremainLimit(data.stripe_customer.email_count);
                setPaginationvar(true);
            })
            .catch((error) => console.log("Error fetching data:", error));
    }, [newDataAdd, navigate, currentpage, recordlimit]);

    const handleChanges = (event, value) => {
        setcurrentpage(value);
    };

    const espOptions = [
        { key: "google", label: "Google" },
        { key: "yahoo", label: "Yahoo" },
        { key: "outlook", label: "Outlook" },
        { key: "m365", label: "M365" },
        { key: "gsuite", label: "GSuite" },
        { key: "custom", label: "Others" },
        { key: "all", label: "All of these" },
    ];

    const maxSelection = espOptions.length - 1;

    useEffect(() => {
        if (selectedValues.length === maxSelection && !selectedValues.includes("all")) {
            setSelectedValues([...selectedValues, "all"]);
        } else if (selectedValues.includes("all") && selectedValues.length !== maxSelection + 1) {
            setSelectedValues(selectedValues.filter((value) => value !== "all"));
        }
    }, [selectedValues]);

    useEffect(() => {
        redirect(token);
    }, [token]);

    function redirect(token) {
        if (token) {
            if (scope) {
                console.log("google hitt");
            } else {
                console.log("outlook hitiitt");
                window.history.replaceState({}, document.title, window.location.pathname);
                fetch(`${REACT_APP_SERVER_URL}/outlookCallback/?code=${token}&login_user=${user.email}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (data.status === 201) {
                            setErrorSnackbarOpen(true);
                            seterrorMsg("user already exists");
                            setNewDataAdd(!newDataAdd);
                            // setErrorMessage(data.msg);
                            return;
                        } else if (data.status === 200) {
                            setSuccessSnackbarOpen(true);
                            setsuccessMsg("Added Successfully");
                            // setSuccessMessage(data.msg);
                            setNewDataAdd(!newDataAdd);
                            // getEmails(date, page);
                        } else if (data.status === 400) {
                            setSuccessSnackbarOpen(true);
                            setNewDataAdd(!newDataAdd);
                            // setSuccessMessage(data.msg);
                            // getEmails(date, page);
                        }
                        setNewDataAdd(!newDataAdd);
                    });
            }
        }
    }

    const handleStartingVolume = (e) => {
        let tempSettingData = settingDrawerData;
        tempSettingData.starting_volume = parseInt(e.target.value);
        setSettingDrawerData(tempSettingData);
        setChange(!change);
        setFlag(true);
    };
    function getStatusDetails(e) {
        let msg = settingAnalyticsData.msg;
        setErrorSnackbarOpen(true);
        seterrorMsg(msg);
    }

    const ChangeStartingVolume = (e) => {
        let startingVolume = parseInt(e.target.value);
        setStartVol(startingVolume);
    };

    const ChangeEndignVolume = (e) => {
        let endingVolume = parseInt(e.target.value);
        setEndVol(endingVolume);
    };

    const changeIncreaseVolume = (e) => {
        let increaseVolume = parseInt(e.target.value);
        setIncPerDay(increaseVolume);
    };

    const ChangeReplyPercent = (e) => {
        let replypercentage = parseInt(e.target.value);
        setReplyPercent(replypercentage);
    };

    const ChangeMaxLimit = (e) => {
        let maxLimitVolume = parseInt(e.target.value);
        setMaxLimit(maxLimitVolume);
    };

    const handleEndingingVolume = (e) => {
        let tempSettingData = settingDrawerData;
        tempSettingData.max_par_day = parseInt(e.target.value);
        setSettingDrawerData(tempSettingData);
        setChange(!change);
    };

    const handleReplyPercentage = (e) => {
        let tempSettingData = settingDrawerData;
        tempSettingData.reply_rate = parseInt(e.target.value);
        setSettingDrawerData(tempSettingData);
        setChange(!change);
    };

    const handleIncreasePerDay = (e) => {
        let tempSettingData = settingDrawerData;
        tempSettingData.increase_per_day = parseInt(e.target.value);
        setSettingDrawerData(tempSettingData);
        setChange(!change);
    };

    const handleSenderMaxLimit = (e) => {
        let tempSettingData = settingDrawerData;
        tempSettingData.sender_max_limit = parseInt(e.target.value);
        setSettingDrawerData(tempSettingData);
        setChange(!change);
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleOpen = () => {
        if (senderLimit <= senderLength) {
            setErrorSnackbarOpen(true);
            seterrorMsg("Reached sender add limit, please update your plan");
            return;
        } else {
            setAddNewOpen(true);
        }
    };
    const handleClose = () => {
        setAddNewOpen(false);
        setGoogleOauth(false);
        setImap(false);
        setLastName("");
        setFirstName("");
        setEmail("");
        setPassword("");
        setSmtpUsername("");
        setSmtpPassword("");
        setSmtpHost("");
        setSmtpPort(587);
        setImapHost("");
        setImapPort(993);
        setGoogleOauthSteps(0);
        setImapSteps(0);
        setUploadCsvFile(false);
        setNewOpen(false);
        setProfessionalOpen(false);
        setUserClient(false);
        setClientNameBox(false);
        setClientNameBox1(false);
    };
    const handleSelectAnotherProvider = () => {
        setGoogleOauth(false);
        setImap(false);
        setEmail("");
        setPassword("");
        setSmtpUsername("");
        setSmtpPassword("");
        setSmtpHost("");
        setSmtpPort(587);
        setImapHost(""); // function CheckAllspamCron() {
        //   try {

        //     fetch(`${REACT_APP_SERVER_URL}/CheckAllspamCron`, {
        //       headers: {
        //         Accept: "application/json",
        //       },
        //       method: "GET",
        //       credentials: "include",
        //     })
        //       .then((response) => {
        //         return response.json();
        //       })
        //       .then((data) => {
        //         console.log(data, 'data coming')
        //       })

        //   }

        //   catch (err) {
        //     console.log(err, 'error')
        //   }
        // }

        // console.log(token, 'tokens')
        setImapPort(993);
        setGoogleOauthSteps(0);
        setImapSteps(0);
    };
    const addNewDataSubmitHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (imap) {
            fetch(`${REACT_APP_SERVER_URL}/Addcampaign`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    fname: firstName,
                    lname: lastName,
                    email: email,
                    smtp_user: smtpUsername,
                    smtp_password: smtpPassword,
                    password: password,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                    imap_host: imapHost,
                    imap_port: imapPort,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setNewDataAdd(!newDataAdd);
                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("accound added");
                        setButtonLoading(false);
                        handleClose();
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        setButtonLoading(false);
                        handleClose();
                        setErrorSnackbarOpen(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        setAddNewErrorMessage(data.msg);
                    }
                })
                .catch((err) => console.error(err));
        }
        if (googleOauth) {
            fetch(`${REACT_APP_SERVER_URL}/Addcampaign`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    fname: firstName,
                    lname: lastName,
                    email: email,
                    smtp_user: smtpUsername,
                    smtp_password: smtpPassword,
                    password: password,
                    smtp_host: "smtp.gmail.com",
                    smtp_port: smtpPort,
                    imap_host: "imap.gmail.com",
                    imap_port: imapPort,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setNewDataAdd(!newDataAdd);
                        setSuccessSnackbarOpen(true);
                        setButtonLoading(false);
                        handleClose();
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        setButtonLoading(false);
                        handleClose();
                        setErrorSnackbarOpen(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        setAddNewErrorMessage(data.msg);
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    const toggleSettingDrawerOpen = (id) => {
        var settingsIndex = getSettingIndex(id);
        var profileIndex = getProfileIndex(id);
        var analyticsIndex = getAnalyticsIndex(id);

        if (tableData?.warmup_analytics[settingsIndex]?.status === "Error") {
            setVisible(true);
        } else {
            setVisible(false);
        }

        setSettingDrawerData(tableData?.warmup_setting[settingsIndex]);

        const profileData = tableData?.warmup_profile[profileIndex];
        if (profileData && profileData.createdAt) {
            const dateString = profileData.createdAt;
            const date = new Date(dateString);
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
            const day = date.getUTCDate();
            const formattedDate = `${day.toString().padStart(2, "0")}-${month.toString().padStart(2, "0")}-${year}`;
            setCreatedAtDate(formattedDate);
        } else {
            setCreatedAtDate("dd-mm-yyyy");
        }

        const updatedProfileData = { ...profileData };
        updatedProfileData.fname = updatedProfileData.fname ? updatedProfileData.fname : "";
        updatedProfileData.lname = updatedProfileData.lname ? updatedProfileData.lname : "";
        setSettingProfileData(updatedProfileData);

        setSettingAnalyticsData(tableData?.warmup_analytics[analyticsIndex]);
        setSettingDrawer(true);
    };

    // const toggleSettingDrawerOpen = (id) => {
    //   var settingsIndex = getSettingIndex(id);
    //   var profileIndex = getProfileIndex(id);
    //   var analyticsIndex = getAnalyticsIndex(id);

    //   if (tableData?.warmup_analytics[settingsIndex].status === "Error") {
    //     setVisible(true);
    //   } else {
    //     setVisible(false);
    //   }

    //   setSettingDrawerData(tableData?.warmup_setting[settingsIndex]);
    //   const dateString = tableData?.warmup_profile[profileIndex].createdAt;
    //   if (dateString) {
    //     const date = new Date(dateString);
    //     const year = date.getUTCFullYear();
    //     const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    //     const day = date.getUTCDate();
    //     const formattedDate = `${day.toString().padStart(2, "0")}-${month
    //       .toString()
    //       .padStart(2, "0")}-${year}`;
    //     setCreatedAtDate(formattedDate);
    //     console.log(formattedDate);
    //   } else {
    //     setCreatedAtDate("dd-mm-yyyy");
    //   }

    //   setSettingProfileData(tableData?.warmup_profile[profileIndex]);

    //   if (settingProfileData !== null) {
    //     settingProfileData.fname = settingProfileData.fname
    //       ? settingProfileData.fname
    //       : "";
    //     settingProfileData.lname = settingProfileData.lname
    //       ? settingProfileData.lname
    //       : "";
    //   }

    //   setSettingAnalyticsData(tableData?.warmup_analytics[analyticsIndex]);
    //   setSettingDrawer(true);
    // // };
    // console.log(settingDrawerData.sender_email, "Drawer data fixed<<<<<<<<<<>>>>>>>>>>");
    function handleReconnectClick() {
        if (usertype === "google") {
            googleOauthHandler(false);
        } else if (usertype === "outlook365-personal") {
            outlookHit();
        } else if (usertype === "smtp") {
            setUpdateDialog(true);
        } else if (usertype === "outlook365-professional") {
            setButtonDisabled(true);
        }
    }

    const fetchWarmupSetting = (mail) => {
        seterrorMsg("");
        let senderEmail = mail;
        fetch(`${REACT_APP_SERVER_URL}/fetchwarmupsetting`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ senderEmail }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setIsVolumeDisabled(data.data.has_freeze);
                    setUsertype(data.data.type);

                    console.log("------------>>>>I am here", data.data.espVisibility);
                    setSettingProfileData(data.data);
                    console.log("++++++________-----", settingProfileData);
                    setMaxEspSelection(data.data.maxEspSelection);
                    setEspVisibility(data.data.espVisibility);
                    console.log(data.data.esp_type, "esp_type from response...");

                    let espTypeArray = data.data.esp_type;
                    if (typeof espTypeArray === "string") {
                        try {
                            espTypeArray = JSON.parse(espTypeArray);
                        } catch (error) {
                            console.error("Error parsing esp_type:", error);
                            espTypeArray = [];
                        }
                    }

                    if (!Array.isArray(espTypeArray)) {
                        espTypeArray = espTypeArray.split(",").map((item) => item.trim());
                    }

                    setSelectedValues(espTypeArray);
                } else if (data.status === 401) {
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.msg);
                    return;
                }
            })

            .catch((err) => console.error(err));
    };

    const toggleSettingDrawerClose = () => {
        // console.log((settingDrawerData.startingVolume));

        // console.log(settingDrawerData.endingVolume);

        // console.log(settingDrawerData.increase_per_day);

        // console.log(settingDrawerData.reply_rate);

        // console.log(settingDrawerData.sender_max_limit);

        // if (
        //   (settingProfileData.fname).length >= 3 &&
        //   (settingProfileData.lname).length >= 0 &&
        //   settingDrawerData.increase_per_day >=
        //   process.env.REACT_APP_increase_per_day_lower_limit &&
        //   settingDrawerData.increase_per_day <=
        //   process.env.REACT_APP_increase_per_day_upper_limit &&
        //   settingDrawerData.reply_rate >=
        //   process.env.REACT_APP_reply_rate_lower_limit &&
        //   settingDrawerData.reply_rate <=
        //   process.env.REACT_APP_reply_rate_upper_limit &&
        //   settingDrawerData.starting_volume >=
        //   process.env.REACT_APP_startingVolume_lower_limit &&
        //   settingDrawerData.starting_volume <=
        //   process.env.REACT_APP_startingVolume_upper_limit &&
        //   settingDrawerData.max_par_day >=
        //   process.env.REACT_APP_endingVolume_lower_limit &&
        //   settingDrawerData.max_par_day <=setbuttonLoading
        //   process.env.REACT_APP_endingVolume_upper_limit &&
        //   settingDrawerData.sender_max_limit >=
        //   process.env.REACT_APP_SendeingMAX_lower_limit &&
        //   settingDrawerData.sender_max_limit <=
        //   process.env.REACT_APP_SendeingMAX_upper_limit
        // )

        if (
            settingDrawerData.starting_volume !== 0 &&
            settingDrawerData.max_par_day > settingDrawerData.starting_volume
        ) {
            setButtonLoading(true);
            fetch(`${REACT_APP_SERVER_URL}/warmup_setting?App_type=xwarmup`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    sender_idsender: settingDrawerData.sender_idsender,
                    fname: settingProfileData.fname,
                    lname: settingProfileData.lname,
                    endingVolume: settingDrawerData.max_par_day,
                    startingVolume: settingDrawerData.starting_volume,
                    increase_per_day: settingDrawerData.increase_per_day,
                    reply_rate: settingDrawerData.reply_rate,
                    current_day_limit: settingDrawerData.current_day_limit,
                    sender_max_limit: settingDrawerData.sender_max_limit,
                    Exclude_Bad_id: settingDrawerData.Exclude_Bad_id,
                    // esp_type: settingProfileData.esp_type,
                    esp_type: selectedValues.filter((value) => value !== "all"),
                    flag: flag,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 200) {
                        setButtonLoading(false);
                        setSettingDrawer(false);
                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("Profile Updated");
                        setNewDataAdd(!newDataAdd);
                        setIsVolumeDisabled(true);
                        // return response.json();
                    } else if (data.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else if (data.status === 403) {
                        setButtonLoading(false);
                        setErrorSnackbarOpen(true);
                        seterrorMsg(data.msg);
                        return;
                    }
                })
                .catch((err) => console.error(err));
        } else {
            setErrorSnackbarOpen(true);
            seterrorMsg(
                "Starting volume should be greater than zero and Ending volume should be greater then starting volume"
            );
            return;
        }
    };

    const removeAccountHandler = () => {
        setButtonLoader(true);
        setAccounctDeleteSuccess(true);
        fetch(`${REACT_APP_SERVER_URL}/deleteaccount`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ email: settingDrawerData["sender_email"] }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setButtonLoader(false);
                    setSettingDrawer(false);

                    setAccounctDeleteSuccess(false);
                    setNewDataAdd(!newDataAdd);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Account deleted");

                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    setButtonLoader(false);
                    setAccounctDeleteSuccess(false);
                    // removeAccountDialog(false);
                }
            })
            .catch((err) => console.error(err));
    };
    const toggleCronjobSetting = (email, active, index) => {
        if (active === 1) {
            fetch(`${REACT_APP_SERVER_URL}/cronejob-stop/${email}`, {
                headers: {
                    Accept: "application/json",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    if (response.status === 200) {
                        let tempTableData = tableData;
                        tempTableData.warmup_setting[index].enable = 0;
                        tempTableData.warmup_analytics[index].status = "paused";
                        setTableData(tempTableData);
                        setCronjobToggle(!cronjobToggle);
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    }
                })
                .catch((err) => console.error(err));
        } else {
            fetch(`${REACT_APP_SERVER_URL}/cronejob-start/${email}`, {
                headers: {
                    Accept: "application/json",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    if (response.status === 200) {
                        let tempTableData = tableData;
                        tempTableData.warmup_setting[index].enable = 1;
                        tempTableData.warmup_analytics[index].status = "Active";
                        setTableData(tempTableData);
                        setCronjobToggle(!cronjobToggle);
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    const getProfileIndex = (sender_idsender) => {
        var ind = -1;
        for (ind = 0; ind < tableData.warmup_profile?.length; ind++) {
            if (sender_idsender === tableData.warmup_profile[ind].idsender) break;
        }
        return ind;
    };

    const getSettingIndex = (sender_idsender) => {
        var ind = -1;
        for (ind = 0; ind < tableData.warmup_setting?.length; ind++) {
            if (sender_idsender === tableData.warmup_setting[ind].sender_idsender) break;
        }
        return ind;
    };
    const getAnalyticsIndex = (sender_idsender) => {
        var ind = -1;
        for (ind = 0; ind < tableData.warmup_analytics?.length; ind++) {
            if (sender_idsender === tableData.warmup_analytics[ind].sender_idsender) break;
        }
        return ind;
    };
    const googleOauthHandler = (newUser) => {
        const isNewUser = newUser;
        fetch(`${REACT_APP_SERVER_URL}/googlereact?App_type=xwarmup&isNewUser=${isNewUser}`, {
            headers: {
                Accept: "application/json",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => window.open(data.data, "_self"))
            .catch((err) => console.error(err));
    };

    const smtpDataCheckHandler = () => {
        setButtonLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/AddaccountSMTP`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: email,
                smtp_user: smtpUsername,
                smtp_password: smtpPassword,
                smtp_host: smtpHost,
                smtp_port: smtpPort,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setButtonLoading(false);
                    imapForwardStepsHandler();
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    setButtonLoading(false);
                    return response.json();
                }
            })
            .then((data) => {
                if (data.status === 201) {
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.msg);
                }
            })
            .catch((err) => console.error(err));
    };
    const googleForwardStepsHandler = () => {
        setGoogleOauthSteps(googleOauthSteps + 1);
    };

    const googleBackStepsHandler = () => {
        setGoogleOauthSteps(googleOauthSteps - 1);
    };
    const googleConnectTypeChangeHandler = (type) => {
        setGoogleConnectTypes(type);
    };
    const imap_smptConnectTypeChangeHandler = (type) => {
        setImapSmptConnectTypes(type);
        setImapSteps(imapSteps + 1);
    };

    const imapForwardStepsHandler = () => {
        setImapSteps(imapSteps + 1);
    };
    const imapBackStepsHandler = () => {
        setImapSteps(imapSteps - 1);
    };
    const clickToCopy = (e) => {
        navigator.clipboard
            .writeText(REACT_APP_GOOGLEAUTH_KEY)
            .then(() => {
                // alert("successfully copied");
                setSuccessSnackbarOpen(true);
                setsuccessMsg("Successfully copied");
            })
            .catch(() => {});
    };
    if (homeLoading === true)
        return (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );

    const filterClose = () => {
        setFilterOpen(!filterOpen);
    };
    const filter = (value) => {
        setFilterValue(value);
    };

    const handleChange = (e) => {
        if (e.target.value === "All") {
            setFiltervalue("All");
            setFilterValue("");
            return;
        } else {
            setFiltervalue(e.target.value);
            setFilterValue(e.target.value);
        }
    };

    function bulkDelete() {
        setDeleteButtonLoading(true);

        fetch(`${REACT_APP_SERVER_URL}/updateorDeleteMultipleWarmup?type=Delete`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: bulkData,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setDeleteButtonLoading(false);
                setdbulkCampaignDialougeOpen(false);
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Deleted Successfully");
                    setbulkData([]);
                    setNewDataAdd(!newDataAdd);
                } else {
                    setErrorSnackbarOpen(true);
                    seterrorMsg("Failed to Deleted,");
                }
            });
    }

    function bulkDataEdit() {
        setEditButtonLoading(true);
        let BulkfirstName = firstName;
        let BulklastName = lastName;
        let email = bulkData;
        let startingVolume = startVol;
        let endingVolume = endVol;
        let sender_max_limit = maxLimit;
        let reply_rate = replyPercent;
        let increase_per_day = incPerDay;

        if (
            !BulkfirstName &&
            !BulklastName &&
            !startingVolume &&
            !endingVolume &&
            !sender_max_limit &&
            !reply_rate &&
            !increase_per_day
        ) {
            setErrorSnackbarOpen(true);
            seterrorMsg("At least one field must be filled");
            setEditButtonLoading(false);
            return;
        }

        if (BulkfirstName && BulkfirstName.length < 3) {
            setErrorSnackbarOpen(true);
            seterrorMsg("First Name length should be equal or more than 3 characters");
            setEditButtonLoading(false);
            return;
        }

        const requestBody = {
            BulkfirstName,
            BulklastName,
            email,
            startingVolume,
            endingVolume,
            sender_max_limit,
            reply_rate,
            increase_per_day,
        };

        const filteredRequestBody = Object.fromEntries(
            Object.entries(requestBody).filter(
                ([key, value]) => value !== undefined && value !== null && value !== NaN && value !== ""
            )
        );
        fetch(`${REACT_APP_SERVER_URL}/updateorDeleteMultipleWarmup?type=Update`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify(filteredRequestBody),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setEditButtonLoading(false);
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Data Updated Successfully");
                    setEditDialougeOpen(false);
                    setNewDataAdd(!newDataAdd);
                    setbulkData([]);
                } else {
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.msg);
                }
            });
    }

    // function handleSelectAll(e) {
    //   if (e.target.checked) {
    //     const allEmails =
    //       tableData?.warmup_analytics.map((row) => row.sender_email) || [];
    //     setbulkData(allEmails);
    //   } else {
    //     setbulkData([]);
    //   }
    // }
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allEmails =
                tableData?.warmup_analytics
                    .filter((row) => row.sender_email.toLowerCase().includes(searchValue.toLowerCase()))
                    .map((row) => row.sender_email) || [];
            setbulkData(allEmails);
        } else {
            setbulkData([]);
        }
    };

    let urlRedirect = [
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=613ff4a8-0eea-4557-8cd0-5b42106cc1b5&scope=email%20Mail.ReadWrite.Shared%20Mail.ReadWrite%20IMAP.AccessAsUser.All%20Mail.Read%20Mail.ReadBasic%20Mail.Send%20MailboxSettings.Read%20openid%20profile%20User.Read%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A4001%2Fdashboard%2Femail_accounts&client-request-id=b30824c7-3ee2-4ade-9818-d64b546a76c0&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=2.6.6&x-client-OS=linux&x-client-CPU=x64&client_info=1&prompt=consent",

        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=613ff4a8-0eea-4557-8cd0-5b42106cc1b5&scope=email%20Mail.ReadWrite.Shared%20Mail.ReadWrite%20IMAP.AccessAsUser.All%20Mail.Read%20Mail.ReadBasic%20Mail.Send%20MailboxSettings.Read%20openid%20profile%20User.Read%20offline_access&redirect_uri=https%3A%2F%2Fb2c.xemailwarmup.com%2Fdashboard%2Femail_accounts&client-request-id=f8290723-3987-4203-a73b-facaec57d360&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=2.6.6&x-client-OS=linux&x-client-CPU=x64&client_info=1&prompt=consent",

        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=613ff4a8-0eea-4557-8cd0-5b42106cc1b5&scope=email%20Mail.ReadWrite.Shared%20Mail.ReadWrite%20IMAP.AccessAsUser.All%20Mail.Read%20Mail.ReadBasic%20Mail.Send%20MailboxSettings.Read%20openid%20profile%20User.Read%20offline_access&redirect_uri=https%3A%2F%2Ftest.xemailwarmup.com%2Fdashboard%2Femail_accounts&client-request-id=92239ae6-4382-43d2-9fe9-661c7cdc870a&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=2.6.6&x-client-OS=linux&x-client-CPU=x64&client_info=1&prompt=consent",
    ];

    function newopen() {
        setNewOpen(true);
        setAddNewOpen(false);
        setProfessionalOpen(false);
        setSendloader(false);
    }

    function handleprofessionalBack() {
        setNewOpen(true);
        setProfessionalOpen(false);
    }
    function ClientcredsBack() {
        setClientNameBox1(true);
        setUserClient(false);
    }
    function Professional() {
        setProfessionalOpen(true);
        setUserClient(false);
        setNewOpen(false);
        setSendloader(false);
        setSearchClients(false);
    }

    function fetchMircosoft(e) {
        console.log(e, "getting the value of e...");
        let clientName = e.name; // e?.target?.innerHTML || null;
        console.log(clientName, "chekcing he clientName...");
        let url = `${REACT_APP_SERVER_URL}/FetchMicrosoftCreds`;
        if (clientName) {
            url = `${REACT_APP_SERVER_URL}/FetchMicrosoftCreds?clientName=${clientName}`;
        }
        setMircosoftLoader(true);
        fetch(url, {
            headers: {
                Accept: "application/json",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setMircosoftLoader(false);
                if (data.status === 200) {
                    setClientId(data.data.client_id);
                    setClientSecret(data.data.client_secret);
                    setTenantId(data.data.tenant_id);
                    setTextFieldDisabled(true);
                } else if (data.status === 401) {
                    setErrorSnackbarOpen(true);
                    seterrorMsg("Invalid Filed ");
                }
                setUserClient(true);
                setProfessionalOpen(false);
            })
            .catch((error) => {
                setErrorSnackbarOpen(true);
                seterrorMsg(error.message || "An error occurred");
            });
    }

    function handleback() {
        setUserClient(true);
        setSearchClients(false);
        setSendloader(false);
        setStoreLoader(false);
    }

    const handleSelectAllCheckbox = (isChecked) => {
        console.log(isChecked, "getting the value of ischecked on select all...");
        const allFilteredSendMailIds = sendmail
            .filter((sender) => sender.mail && sender.mail.includes(SearchClient))
            .map((sender) => sender.id);
        setchooseClient(isChecked ? allFilteredSendMailIds : []);
        const updatedSendMail = sendmail.map((sender) => ({
            ...sender,
            checkedByUser: isChecked,
            existsInDB: null,
        }));
        setSendMail(updatedSendMail);
        if (isChecked === false) {
            setUncheckPopUp(true);
        }
    };
    const handleClientClick = (senderId) => {
        const updatedSendMail = sendmail.map((item) => {
            if (item.id === senderId) {
                return { ...item, checkedByUser: !item.checkedByUser };
            }
            return item;
        });

        setSendMail(updatedSendMail);
    };

    async function AllUsersInOrganization(buttonType) {
        console.log("Button type received:", buttonType);
        const addNewCreds = buttonType === "Add";

        // Frontend validation for required fields
        if (!clientId || !tenantId || !clientSecret) {
            setErrorSnackbarOpen(true);
            seterrorMsg("All fields are required.");
            return;
        }

        setSendloader(true);

        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/fetchOrganisationUser`, {
                headers: { Accept: "application/json" },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    clientId,
                    tenantId,
                    clientSecret,
                    clientName: clientname,
                    addNewCreds: addNewCreds.toString(),
                }),
            });

            const data = await response.json();
            setSendloader(false);

            // Success handling
            if (response.ok) {
                setSearchClients(true);
                setUserClient(false);
                setSendMail(data.data);
                setSuccessSnackbarOpen(true);
                setsuccessMsg(data.message || "Data updated successfully.");
            } else {
                // Error handling based on status and backend message
                setErrorSnackbarOpen(true);
                seterrorMsg(data.message || "An error occurred while processing the request.");
            }
        } catch (error) {
            setSendloader(false);
            setErrorSnackbarOpen(true);
            seterrorMsg(error.message || "An error occurred while connecting to the server.");
        }
    }

    function outlookHit() {
        fetch(`${REACT_APP_SERVER_URL}/outlook`, {
            headers: {
                Accept: "application/json",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((d) => {
                if (d.status === 200) {
                    // window.location.replace()
                    window.location.replace(d.data);
                }
            });
    }

    const handleBackToUpdateDialog = () => {
        setUpdateDialog(true);
        setIMAPDialog(false);
    };

    const SMTPUpdateNext = () => {
        setSMTPButtonLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/updateSMTPPassword`, {
            headers: {
                Accept: "application/json",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                email: settingDrawerData.sender_email,
                smtp_password: smtpPassword,
                smtp_host: smtpHost,
                smtp_port: smtpPort,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setSMTPButtonLoading(false);
                    setIMAPDialog(true);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Data Updated Successfully");
                } else {
                    setSMTPButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    seterrorMsg("Invalid SMTP Password");
                }
            });
    };
    const UpdateIMAP = () => {
        setIMAPButtonLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/updateIMAPPassword`, {
            headers: {
                Accept: "application/json",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                email: settingDrawerData.sender_email,
                password: password,
                imap_host: imapHost,
                imap_port: imapPort,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setIMAPButtonLoading(false);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg("Updated SMTP Password Successfully");
                    setIMAPDialog(false);
                    setUpdateDialog(false);
                } else {
                    setIMAPButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    seterrorMsg("Invalid IMAP Password");
                }
            });
    };

    function backfunction() {
        setCsvRecords([]);
        setNewDataAdd(!newDataAdd);
    }

    const getFileLogData = async (fileName) => {
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/getFileLogData/?fileName=${fileName[0].name}`, {
                method: "get",
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setCsvData(data.data);
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, error: "Internal server error" };
        }
    };

    const CsvFileUpload = async (event) => {
        if (fileUpload === null) {
            setErrorSnackbarOpen(true);
            seterrorMsg("First upload the file");
            return;
        }
        setImap(false);
        setCsvFileLoader(true);
        setUploadCsvFile(false);
        const fileInput = document.querySelector('input[name="csvFileInput"]');

        const formData = new FormData();
        formData.append("csvFile", fileInput.files[0]);
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/csvFileUpload`, {
                method: "POST",
                credentials: "include",
                body: formData,
            });

            if (!response.ok) {
                console.error("File upload failed");
                return;
            }
            const data = await response.json();
            setCsvFileLoader(false);
            setCsvRecords(true);
            setCsvData(data.data);
            setStoreFileName(data.data);
            const fileLogResponse = await getFileLogData(data.data); // Call getFileLogData

            if (fileLogResponse.success) {
                console.log("File log response:", fileLogResponse.data);
            } else {
                console.error(fileLogResponse.error);
            }
        } catch (error) {
            setErrorSnackbarOpen(true);
            seterrorMsg("CsvFile upload");
        }
    };

    const downloadFile = async () => {
        try {
            const response = await fetch(
                `${REACT_APP_SERVER_URL}/getFileErrorLogData?fileName=${storeFileName[0].name}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                    credentials: "include",
                }
            );

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to fetch file error log data: ${errorText}`);
            }

            const data = await response.blob();

            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(data);
            downloadLink.download = `${storeFileName[0].name}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error("Error fetching file error log data:", error);
            throw error;
        }
    };

    const handleOpenCsvFile = () => {
        setUploadCsvFile(true);
        setAddNewOpen(false);
    };
    const handlecloseCsvFile = () => {
        setUploadCsvFile(false);
    };

    function handleFileUpload(event) {
        const file = event.target.files[0];
        console.log(event.target.files[0].name.split(" ").join(""));
        setFileUpload(file);
        setSuccessSnackbarOpen(true);
        setsuccessMsg("File loaded");
    }
    let dummyData = [
        [
            "Email",
            "First Name",
            "Last Name",
            "IMAP Username	",
            "IMAP Password",
            "IMAP Host",
            "IMAP Port",
            "SMTP Username",
            "SMTP Password",
            "SMTP Host",
            "SMTP Port",
            "Daily Limit",
            "Warmup Enabled",
            "Warmup Limit",
            "Warmup Increment",
        ],
        [
            "dummy@gmail.com",
            "dummy",
            "data",
            "dummy@gmail.com",
            "Dummy@123",
            "imap.gmail.com",
            "993",
            "dummy@gmail.com",
            "Dummy@123",
            "smtp.gmail.com",
            "587",
            "50",
            "TRUE",
            "200",
            "disabled",
        ],
    ];

    const storeSenders = () => {
        setStoreLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/storeSenders`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                senders: sendmail,
                clientId: clientId,
                clientSecret: clientSecret,
                tenantId: tenantId,
                name: clientname,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setStoreLoader(false);
                setSearchClients(false);
                if (data.status === 200) {
                    setNewDataAdd(!newDataAdd);
                    setSuccessSnackbarOpen(true);
                    setsuccessMsg(data.msg);
                    setIsVolumeDisabled(data.data.has_freeze);
                    handleClose();
                    return;
                } else if (data.status === 401) {
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.msg);
                    return;
                }
                setNewDataAdd(!newDataAdd);
            })
            .catch((err) => console.error(err));
    };

    const handleDownloadPDF = () => {
        const link = document.createElement("a");
        link.href = photo;
        link.download = "admin.pdf";
        link.click();
    };

    let statusAndEmailFilter = tableData?.warmup_analytics
        ?.filter((x) => x["status"].includes(filterValue))
        .filter((x) => x["sender_email"].toLowerCase().includes(searchValue.toLowerCase()));

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const totalPages = Math.ceil(statusAndEmailFilter && statusAndEmailFilter.length / recordsPerPage);

    const currentRecords = searchValue
        ? statusAndEmailFilter && statusAndEmailFilter
        : tableData?.warmup_analytics
              ?.filter((x) => x["status"].includes(filterValue))
              .slice(indexOfFirstRecord, indexOfLastRecord);

    // Handled Paginations...

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        const pageButtons = pageNumbers.map((number) => {
            if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
                return (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`page-button ${currentPage === number ? "active" : ""}`}
                    >
                        {number}
                    </button>
                );
            } else if (number === currentPage - 3 || number === currentPage + 3) {
                return (
                    <span key={number} className="ellipsis">
                        ...
                    </span>
                );
            }
            return null;
        });

        return pageButtons;
    };

    const handleCheckboxChange = (key) => {
        console.log(key, "finding the key...", selectedValues);
        if (key === "all") {
            if (selectedValues.includes("all")) {
                setSelectedValues([]);
            } else {
                setSelectedValues(espOptions.filter((option) => option.key !== "all").map((option) => option.key));
            }
        } else {
            if (selectedValues.includes(key)) {
                setSelectedValues(selectedValues.filter((value) => value !== key));
            } else if (selectedValues.length >= maxEspSelection) {
                setErrorSnackbarOpen(true);
                seterrorMsg(`You can select up to ${maxEspSelection} ESPs only.`);
            } else {
                setSelectedValues([...selectedValues, key]);
            }
        }
    };

    console.log(selectedValues, "printingSelectedValues...");

    return (
        <ThemeProvider theme={colorTheme}>
            <Dialog open={filterOpen} onClose={filterClose}>
                <Box sx={{ width: "300px", height: "300px", textAlign: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography p={1}>Status:</Typography>
                        <Button onClick={() => filter("Pause")}>Paused</Button>
                        <Button onClick={() => filter("Active")}>Active</Button>
                        <Button onClick={() => filter("Error")}>Error</Button>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" },
                }}
                open={NewOpen}
            >
                <Box>
                    <Typography
                        align="center"
                        color="primary"
                        sx={{ fontWeight: "700", fontSize: "18px", margin: "20px" }}
                    >
                        Select a connection option
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 4,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} onClick={outlookHit}>
                            <StyledBox
                                sx={{
                                    borderRadius: "10px",
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                    height: "fit-content",
                                    width: "250px",
                                    cursor: "pointer",
                                    p: 4,
                                }}
                            >
                                <Typography
                                    align="center"
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        mb: 5,
                                    }}
                                >
                                    Personal/Outllook.com
                                </Typography>
                            </StyledBox>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} onClick={Professional}>
                            <StyledBox
                                sx={{
                                    borderRadius: "10px",
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                    height: "fit-content",
                                    width: "250px",
                                    cursor: "pointer",
                                    p: 4,
                                }}
                            >
                                <Typography
                                    align="center"
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        mb: 2,
                                    }}
                                >
                                    Professional/M365 (Customdomain)
                                </Typography>
                            </StyledBox>
                        </Grid>
                    </Grid>
                </Box>
                <DialogActions>
                    <Box
                        sx={{
                            width: "100%",
                            fontWeight: "700",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                        }}
                    >
                        <IconButton onClick={handleOpen}>
                            <ArrowBackIcon />
                        </IconButton>
                        Select another provider
                    </Box>
                    <Box>
                        <Button variant="outlined" onClick={handleClose} sx={{ margin: "10px" }}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" },
                }}
                open={professionalopen}
            >
                <Box
                    sx={{
                        padding: "20px",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                        <ArrowBackIcon
                            sx={{
                                marginRight: "10px",
                                cursor: "pointer",
                                color: "primary.main",
                                transition: "color 0.3s",
                                "&:hover": { color: "#004AAD" },
                            }}
                            onClick={handleprofessionalBack}
                        />
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "text.secondary",
                                lineHeight: "1.4",
                            }}
                        >
                            Here’s a guide on how to create an application in Azure. Download it if you need assistance,
                            or if you’re already familiar, simply continue to the next step.
                        </Typography>
                    </Box>

                    <DialogActions sx={{ justifyContent: "flex-end" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                            sx={{
                                marginRight: "auto",
                                paddingX: "20px",
                                fontWeight: "600",
                                textTransform: "none",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleDownloadPDF}
                            sx={{
                                marginRight: "20px",
                                paddingX: "20px",
                                fontWeight: "600",
                                textTransform: "none",
                            }}
                        >
                            Download
                        </Button>

                        {mircosoftloader ? (
                            <CircularProgress size={24} sx={{ marginLeft: "20px" }} />
                        ) : (
                            <Button
                                variant="contained"
                                onClick={ClientName}
                                sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    backgroundColor: "#004AAD",
                                    color: "#fff",
                                    paddingX: "20px",
                                    "&:hover": {
                                        backgroundColor: "#003580",
                                    },
                                }}
                            >
                                Next
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: "20px",
                        width: "100%",
                        padding: "20px",
                    },
                }}
                open={clienamebox}
            >
                <Box>
                    <ArrowBackIcon
                        onClick={handleClientBack}
                        sx={{
                            cursor: "pointer",
                            marginBottom: "20px",
                            color: "primary.main",
                            transition: "color 0.3s",
                            "&:hover": { color: "#004AAD" },
                        }}
                    />

                    <DialogTitle sx={{ textAlign: "center", padding: "10px" }}>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#333",
                                marginBottom: "10px",
                            }}
                        >
                            Share Client Details
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: "#666",
                                marginBottom: "15px",
                            }}
                        >
                            Please provide the client name to proceed.
                        </Typography>
                        <TextField
                            label="Client Name"
                            variant="outlined"
                            fullWidth
                            value={clientname}
                            onChange={(e) => setClientName(e.target.value)}
                            sx={{
                                marginBottom: "20px",
                            }}
                        />
                    </DialogTitle>

                    <DialogActions sx={{ justifyContent: "space-between" }}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{
                                margin: "10px",
                                textTransform: "none",
                                color: "#004AAD",
                                borderColor: "#004AAD",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#004AAD",
                                margin: "10px",
                                "&:hover": { backgroundColor: "#003580" },
                            }}
                            onClick={handleClientNameChange}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: "20px",
                        width: "100%",
                        padding: "10px",
                    },
                }}
                open={clienamebox1}
            >
                <Box>
                    <ArrowBackIcon onClick={handleAllClientBack} sx={{ cursor: "pointer", marginBottom: "10px" }} />
                    <Box>
                        {allClients && allClients.length > 0 ? (
                            <DialogTitle sx={{ padding: "20px", backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Client List
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        color: "#666",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Click to choose an existing client
                                </Typography>
                                <ul
                                    style={{
                                        listStyleType: "none",
                                        padding: 0,
                                        margin: 0,
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }}
                                >
                                    {allClients.map((client) => (
                                        <li
                                            key={client.id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                padding: "12px",
                                                fontSize: "16px",
                                                color: "#333",
                                                borderBottom: "1px solid #e0e0e0",
                                                backgroundColor: "#fff",
                                                transition: "background-color 0.3s",
                                                justifyContent: "space-between",
                                            }}
                                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
                                        >
                                            <span onClick={() => fetchMircosoft(client)}>{client.name}</span>
                                            <DeleteIcon
                                                onClick={() => {
                                                    openDeleteConfirmation(client);
                                                }}
                                                sx={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </DialogTitle>
                        ) : (
                            <DialogTitle
                                sx={{
                                    padding: "20px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "8px",
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        color: "#333",
                                        marginBottom: "10px",
                                    }}
                                >
                                    No Clients Available
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "#666",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Add your client and application details to proceed.
                                </Typography>
                            </DialogTitle>
                        )}

                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <Button variant="outlined" onClick={handleClose} sx={{ margin: "10px" }}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    backgroundColor: "#004AAD",
                                    margin: "10px",
                                }}
                                onClick={handleClientName}
                            >
                                Add New Client
                            </Button>
                        </div>
                    </Box>
                </Box>

                {/* Delete Confirmation Dialog */}
                <Dialog open={confirmDelete} onClose={closeDeleteConfirmation}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete the client {clientToDelete?.name}? This will remove all
                            associated credentials and users.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteConfirmation} color="primary">
                            Cancel
                        </Button>
                        {deleteM365Loader ? (
                            <CircularProgress size={24} sx={{ marginLeft: "20px" }} />
                        ) : (
                            <Button onClick={confirmDeleteClient} color="error" variant="contained">
                                Yes, Delete
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: "20px",
                        width: "100%",
                        padding: "10px",
                    },
                }}
                open={userclient}
            >
                <Box>
                    <>
                        <ArrowBackIcon onClick={ClientcredsBack} />
                    </>
                    <Box>
                        <DialogTitle
                            sx={{
                                padding: "20px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    color: "#333",
                                    marginBottom: "16px",
                                }}
                            >
                                Enter Client Details
                            </Typography>

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#666",
                                    marginBottom: "20px",
                                }}
                            >
                                Please provide the required credentials to continue.
                            </Typography>

                            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                                <div>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#555" }}>
                                        Client ID
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "5px",
                                            backgroundColor: "#fff",
                                            borderRadius: "4px",
                                            "& .MuiOutlinedInput-root": { borderColor: "#ddd" },
                                        }}
                                        value={clientId}
                                        onChange={(e) => {
                                            setIsClientIdModified(true);
                                            setClientId(e.target.value);
                                        }}
                                        disabled={textfielddisabled === true}
                                    />
                                </div>

                                <div>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#555" }}>
                                        Tenant ID
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "5px",
                                            backgroundColor: "#fff",
                                            borderRadius: "4px",
                                            "& .MuiOutlinedInput-root": { borderColor: "#ddd" },
                                        }}
                                        value={tenantId}
                                        onChange={(e) => {
                                            setIsTenantIdModified(true);
                                            setTenantId(e.target.value);
                                        }}
                                        disabled={textfielddisabled === true}
                                    />
                                </div>

                                <div>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#555" }}>
                                        Client Secret
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "5px",
                                            backgroundColor: "#fff",
                                            borderRadius: "4px",
                                            "& .MuiOutlinedInput-root": { borderColor: "#ddd" },
                                        }}
                                        value={clientSecret}
                                        onChange={handleClientSecretChange} // Track changes specifically for clientSecret

                                        // disabled={textfielddisabled === true}
                                    />
                                </div>
                            </div>
                        </DialogTitle>

                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <Button variant="outlined" onClick={handleClose} sx={{ margin: "10px" }}>
                                Cancel
                            </Button>

                            {sendloader ? (
                                <CircularProgress sx={{ marginTop: "10px" }} size={25} />
                            ) : (
                                <>
                                    {/* Show Update Secret Key button only if clientSecret is modified */}
                                    {isSecretKeyModified && !isClientIdModified && !isTenantIdModified && (
                                        <Button
                                            variant="contained"
                                            onClick={handleUpdateSecretKey}
                                            sx={{
                                                textTransform: "none",
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                backgroundColor: "#004AAD",
                                                margin: "10px",
                                            }}
                                        >
                                            Update Secret Key
                                        </Button>
                                    )}

                                    {/* Show either "Next" or "Add" button based on textfielddisabled */}
                                    <Button
                                        variant="contained"
                                        onClick={() => AllUsersInOrganization(textfielddisabled ? "Next" : "Add")}
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            backgroundColor: "#004AAD",
                                            margin: "10px",
                                        }}
                                    >
                                        {textfielddisabled ? "Next" : "Add"}
                                    </Button>
                                </>
                            )}
                        </div>

                        <DialogActions></DialogActions>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: "20px",
                        width: "100%",
                        padding: "20px",
                        maxWidth: "600px",
                    },
                }}
                open={searchclients}
            >
                <Box>
                    <ArrowBackIcon
                        onClick={handleback}
                        sx={{
                            cursor: "pointer",
                            color: "#004AAD",
                            transition: "color 0.3s",
                            "&:hover": { color: "#003580" },
                            marginBottom: "15px",
                        }}
                    />

                    <DialogTitle sx={{ textAlign: "center", padding: "0 20px" }}>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "700",
                                color: "#333",
                                marginBottom: "10px",
                            }}
                        >
                            User Email List
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#666",
                                marginBottom: "15px",
                            }}
                        >
                            Select existing users or add a new user email to the list
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <TextField
                                    label="Search Emails"
                                    id="outlined-size-small"
                                    size="medium"
                                    variant="outlined"
                                    value={SearchClient}
                                    onChange={(e) => setSearchClient(e.target.value)}
                                    sx={{
                                        width: "70%",
                                        backgroundColor: "#f9f9f9",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                                {storeloader ? (
                                    <CircularProgress sx={{ marginLeft: "20px" }} size={25} />
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={handleConfirmProceed} // Show confirmation dialog on click
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            backgroundColor: "#004AAD",
                                            marginLeft: "20px",
                                            padding: "10px 16px",
                                            "&:hover": { backgroundColor: "#003580" },
                                        }}
                                    >
                                        Add New Email
                                    </Button>
                                )}
                            </Box>

                            <MenuList sx={{ marginTop: "20px", padding: 0 }}>
                                <MenuItem
                                    key="select-all"
                                    sx={{ backgroundColor: "#f5f5f5", borderRadius: "8px", marginBottom: "10px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const isChecked =
                                            chooseClient.length !==
                                            sendmail.filter(
                                                (sender) => sender.mail && sender.mail.includes(SearchClient)
                                            ).length;
                                        handleSelectAllCheckbox(isChecked);
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            chooseClient.length ===
                                            sendmail.filter(
                                                (sender) => sender.mail && sender.mail.includes(SearchClient)
                                            ).length
                                        }
                                        onChange={(e) => handleSelectAllCheckbox(e.target.checked)}
                                    />
                                    Select All
                                </MenuItem>

                                {sendmail &&
                                    sendmail
                                        .filter((sender) => sender.mail && sender.mail.includes(SearchClient))
                                        .map(
                                            (sender) =>
                                                sender.mail && (
                                                    <MenuItem
                                                        key={sender.id}
                                                        // onClick={() => handleClientClick(sender.id)}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderBottom: "1px solid #e0e0e0",
                                                            transition: "background-color 0.3s",
                                                            borderRadius: "8px",
                                                            "&:hover": {
                                                                backgroundColor: "#f0f0f0",
                                                            },
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={sender.checkedByUser || sender.existsInDB}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                const updatedSendMail = sendmail.map((item) => {
                                                                    if (
                                                                        item.id === sender.id ||
                                                                        item.mail === sender.mail
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            checkedByUser: isChecked,
                                                                            existsInDB: null,
                                                                        };
                                                                    }
                                                                    return item;
                                                                });
                                                                setSendMail(updatedSendMail);
                                                                if (isChecked === false) {
                                                                    setUncheckPopUp(true);
                                                                }
                                                            }}
                                                        />
                                                        {sender.mail}
                                                    </MenuItem>
                                                )
                                        )}
                            </MenuList>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>

            <Dialog open={uncheckPopUp} onClose={() => setUncheckPopUp(false)}>
                <DialogTitle>
                    <Typography sx={{ fontSize: "18px", fontWeight: "700", color: "#333" }}>Confirm Action</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontSize: "14px", color: "#666", padding: "10px" }}>
                        Unselecting a checkbox will remove the associated email and data from the database. Only
                        selected emails will be retained. Are you sure you want to continue?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUncheckPopUp(false)} variant="outlined" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {!settingDrawer && (
                <>
                    <Box sx={{ backgroundColor: "#fff", borderRadius: 3 }}>
                        <Box sx={{ width: "100%", height: "4rem" }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            height: "45px",
                                            borderRadius: "6px",
                                            boxShadow: "none",
                                            border: "none",
                                            color: "#989898",
                                            backgroundColor: "#F7F7F9",
                                            fontSize: "14px",
                                            width: "550px",
                                        }}
                                    >
                                        <SearchIcon sx={{ color: "#000000b0", mr: 1, fontWeight: "400" }} />
                                        <InputBase
                                            sx={{ flex: 1, border: "none", width: "100%" }}
                                            placeholder="Search; keyword, title of campaign"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            inputProps={{
                                                "aria-label": "search for projects",
                                                sx: {
                                                    "&::placeholder": {
                                                        color: "#989898",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        fontFamily: "'Inter, Sans-serif",
                                                    },
                                                },
                                            }}
                                        />
                                    </Paper>
                                    {/* <Button
                    variant="outlined"
                    sx={{ color: "grey", borderColor: "grey" }}
                    onClick={() => setFilterOpen(true)}
                  >
                    <FilterAltOffIcon
                      style={{ fontSize: "20px", paddingRight: "5px" }}
                    />
                    Filter
                  </Button> */}
                                    <FormControl sx={{ width: "100px" }} size="small">
                                        <InputLabel id="demo-simple-select-autowidth-label">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                <img src={filterIcon} alt="icon" width="18px" /> <span>Filter</span>
                                            </Stack>
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={filtervalue}
                                            onChange={handleChange}
                                            autoWidth
                                            label="Filter"
                                            inputProps={{
                                                IconComponent: () => null,
                                                sx: { display: "flex", alignItems: "center" },
                                            }}
                                            sx={{ height: "45px", pr: 0 }}
                                        >
                                            <MenuItem
                                                value={"paused"}
                                                sx={{
                                                    width: "150px",
                                                    color: "green",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // flexDirection: "column",
                                                    fontSize: "14px",
                                                    '&[aria-selected="true"]': {
                                                        fontSize: "12px",
                                                    },
                                                }}
                                            >
                                                <PauseCircleIcon
                                                    sx={{
                                                        transform: "",
                                                        fontSize: "20px",
                                                        position: "relative",
                                                        top: "-1px",
                                                    }}
                                                />
                                                &nbsp;Paused
                                            </MenuItem>

                                            <MenuItem
                                                value={"Active"}
                                                sx={{
                                                    width: "150px",
                                                    color: "green",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // flexDirection: "column",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <DoneAllIcon
                                                    sx={{
                                                        transform: "",
                                                        fontSize: "20px",
                                                        position: "relative",
                                                        top: "-1px",
                                                    }}
                                                />
                                                &nbsp; Active
                                            </MenuItem>

                                            <MenuItem
                                                value={"Error"}
                                                sx={{
                                                    width: "150px",
                                                    color: "purple",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // flexDirection: "column",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <ErrorIcon
                                                    sx={{
                                                        transform: "",
                                                        fontSize: "20px",
                                                        position: "relative",
                                                        top: "-1px",
                                                    }}
                                                />
                                                &nbsp; Error
                                            </MenuItem>

                                            <MenuItem
                                                value={"All"}
                                                sx={{
                                                    width: "150px",
                                                    color: "black",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <AppsOutlinedIcon
                                                    sx={{
                                                        transform: "",
                                                        fontSize: "20px",
                                                        position: "relative",
                                                        top: "-1px",
                                                    }}
                                                />
                                                &nbsp; All
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {/* <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    Open Menu
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={menuhandleClose}
                                >
                                    <MenuItem onClick={() => menuhandleClose(0, 0)}>Paused</MenuItem>
                                    <MenuItem onClick={() => menuhandleClose(1, 0)}>Active</MenuItem>
                                    <MenuItem onClick={() => menuhandleClose(0, 1)}>Error</MenuItem>
                                </Menu> */}
                                <>
                                    {bulkData && bulkData.length !== 0 ? (
                                        <div>
                                            <Button
                                                sx={{
                                                    color: "#9491AD",
                                                    textTransform: "none",
                                                    fontSize: "14px",
                                                    fontWeight: "700",
                                                    width: "100px",
                                                    border: "0.5px solid gray",
                                                }}
                                                onClick={() => {
                                                    setEditDialougeOpen(true);
                                                }}
                                            >
                                                <EditIcon></EditIcon>
                                            </Button>

                                            <Button
                                                sx={{
                                                    color: "#EF6D6D",
                                                    textTransform: "none",
                                                    fontSize: "14px",
                                                    fontWeight: "700",
                                                    width: "100px",
                                                    border: "0.5px solid gray",
                                                    ml: "10px",
                                                }}
                                                onClick={() => {
                                                    setdbulkCampaignDialougeOpen(true);
                                                }}
                                            >
                                                <Delete />
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={handleOpen}
                                            sx={{
                                                textTransform: "none",
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                backgroundColor: "#004AAD",
                                                width: "150px",
                                            }}
                                        >
                                            + Add new
                                        </Button>
                                    )}

                                    <Dialog
                                        PaperProps={{
                                            style: { borderRadius: "20px" },
                                        }}
                                        open={editDialougeOpen}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "'center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                <p>Edit Data in Bulk</p>
                                                <Grid container rowSpacing={2} columnSpacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <StyledTypography>First Name</StyledTypography>
                                                        <StyledTextField
                                                            fullWidth
                                                            placeholder="First Name"
                                                            value={firstName}
                                                            onChange={(e) => {
                                                                console.log(e.target.value.trim());
                                                                setFirstName(e.target.value.trim());
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <StyledTypography>Last Name</StyledTypography>
                                                        <StyledTextField
                                                            fullWidth
                                                            placeholder="Last Name"
                                                            value={lastName}
                                                            onChange={(e) => {
                                                                setLastName(e.target.value.trim());
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <StyledTypography>{`Ending Volume (1-(${mailLimit}))`}</StyledTypography>
                                                        <StyledTextField
                                                            fullWidth
                                                            type="number"
                                                            inputProps={{
                                                                min: "1",
                                                                max: mailLimit,
                                                            }}
                                                            onChange={(e) => ChangeEndignVolume(e)}
                                                            sx={{
                                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                    {
                                                                        display: "none",
                                                                    },
                                                                "& input[type=number]": {
                                                                    MozAppearance: "textfield",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <StyledTypography>Reply Percent (0-50)</StyledTypography>
                                                        <StyledTextField
                                                            fullWidth
                                                            type="number"
                                                            inputProps={{
                                                                min: "0",
                                                                max: "50",
                                                            }}
                                                            onChange={(e) => ChangeReplyPercent(e)}
                                                            sx={{
                                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                    {
                                                                        display: "none",
                                                                    },
                                                                "& input[type=number]": {
                                                                    MozAppearance: "textfield",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <StyledTypography>Increase per day (1-50)</StyledTypography>
                                                        <StyledTextField
                                                            fullWidth
                                                            type="number"
                                                            onChange={(e) => changeIncreaseVolume(e)}
                                                            inputProps={{
                                                                min: "1",
                                                                max: "50",
                                                            }}
                                                            sx={{
                                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                    {
                                                                        display: "none",
                                                                    },
                                                                "& input[type=number]": {
                                                                    MozAppearance: "textfield",
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                        </div>

                                        <DialogActions>
                                            {EditButtonLoading ? (
                                                <CircularProgress size={25} />
                                            ) : (
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            bulkDataEdit();
                                                        }}
                                                        variant="contained"
                                                        sx={{ color: "#fff", marginLeft: "20px" }}
                                                        disabled={false}
                                                    >
                                                        Bulk Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setEditDialougeOpen(false);
                                                        }}
                                                        variant="contained"
                                                        sx={{ color: "#fff", marginLeft: "20px" }}
                                                        color="error"
                                                        disabled={false}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )}
                                        </DialogActions>
                                    </Dialog>

                                    <Dialog
                                        PaperProps={{
                                            style: { borderRadius: "20px" },
                                        }}
                                        open={deletebulkDialougeOpen}
                                    >
                                        <div
                                            style={{
                                                width: "500px",
                                                height: "200px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                <p
                                                    style={{
                                                        color: "red",
                                                        fontWeight: "bold",
                                                        fontSize: "20px",
                                                    }}
                                                >
                                                    Are you sure you want to delete account?
                                                </p>
                                                <p
                                                    style={{
                                                        color: "red",
                                                        fontWeight: "bold",
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    By this you will lost your all previous data.
                                                </p>
                                            </DialogTitle>
                                            <DialogActions sx={{ mb: 4, float: "right" }}>
                                                {deleteButtonLoading ? (
                                                    <CircularProgress size={25} />
                                                ) : (
                                                    <div>
                                                        <Button
                                                            onClick={() => setdbulkCampaignDialougeOpen(false)}
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#38C68B",
                                                                color: "#fff",
                                                                fontSize: "12px",
                                                                px: 4,
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={() => bulkDelete()}
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#EF6D6D",
                                                                color: "#fff",
                                                                fontSize: "12px",
                                                                px: 4,
                                                                marginLeft: "20px",
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                )}
                                            </DialogActions>
                                        </div>
                                    </Dialog>

                                    <Dialog
                                        open={uploadCsvFile}
                                        maxWidth="md"
                                        PaperProps={{
                                            style: {
                                                borderRadius: "20px",
                                                pt: 3,
                                                pb: 3,
                                            },
                                        }}
                                    >
                                        <Box sx={{ margin: "20px" }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    mb: 4,
                                                }}
                                            >
                                                <img
                                                    src={imap_smtp}
                                                    alt="imap_smtp"
                                                    sx={{ float: "left", width: "25px" }}
                                                />
                                                <Box sx={{ ml: 2 }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "18px",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        Connect any provider
                                                    </Typography>
                                                    <Typography>IMAP / SMTP</Typography>
                                                </Box>
                                            </Box>
                                            <>
                                                <Card
                                                    sx={{
                                                        width: "80%",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flexDirection: "column",
                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 2px",
                                                        margin: "10px",
                                                        marginLeft: "45px",
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            margin: "20px",
                                                        }}
                                                    >
                                                        <div style={{ marginTop: "5px" }}>
                                                            <CSVLink
                                                                data={dummyData}
                                                                filename={"dummyData.csv"}
                                                                style={{
                                                                    width: "200px",
                                                                    height: "20px",
                                                                    border: "none",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#004aad",
                                                                    textDecoration: "none",
                                                                    color: "white",
                                                                    padding: "10px",
                                                                    margin: "10px",
                                                                    fontSize: "12px",
                                                                    "&:hover": {
                                                                        backgroundColor: "#1565c0",
                                                                    },
                                                                }}
                                                            >
                                                                Download Sample
                                                            </CSVLink>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                marginTop: "40px",
                                                                marginLeft: "30px",
                                                            }}
                                                        >
                                                            <UploadFileIcon
                                                                style={{
                                                                    fontSize: "48px",
                                                                    marginBottom: "10px",
                                                                    color: "#2196f3",
                                                                }}
                                                            ></UploadFileIcon>
                                                            <input
                                                                style={{ marginTop: "15px" }}
                                                                type="file"
                                                                id="csvfile"
                                                                name="csvFileInput"
                                                                onChange={handleFileUpload}
                                                            />
                                                        </div>

                                                        <Button
                                                            onClick={CsvFileUpload}
                                                            variant="contained"
                                                            component="label"
                                                            style={{
                                                                backgroundColor: "#004aad",
                                                                color: "#fff",
                                                                marginTop: "30px",
                                                            }}
                                                        >
                                                            UploadFile
                                                        </Button>
                                                    </CardContent>
                                                </Card>
                                                <DialogActions>
                                                    <Box>
                                                        <Button
                                                            sx={{ marginRight: "300px" }}
                                                            variant="contained"
                                                            onClick={() => setAddNewOpen(true)}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            onClick={handlecloseCsvFile}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </DialogActions>
                                            </>
                                        </Box>
                                    </Dialog>

                                    <Dialog
                                        onClose={handleClose}
                                        open={addNewOpen}
                                        maxWidth="md"
                                        PaperProps={{
                                            style: {
                                                borderRadius: "20px",
                                                pt: 3,
                                                pb: 3,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                "&::-webkit-scrollbar": {
                                                    width: "4px",
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    background: "#481BEB",
                                                    borderRadius: "4px",
                                                },
                                                "&::-webkit-scrollbar-thumb:hover": {
                                                    background: "#2b2b2b",
                                                },
                                                overflow: "auto",
                                            }}
                                        >
                                            <DialogTitle onClose={handleClose}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "700",
                                                        fontSize: "18px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Connect a new Email account
                                                </Typography>
                                            </DialogTitle>
                                            <DialogContent
                                                sx={{
                                                    "&::-webkit-scrollbar": {
                                                        width: "2px",
                                                        height: "2px",
                                                    },
                                                    "&::-webkit-scrollbar-thumb": {
                                                        background: "#481BEB",
                                                        borderRadius: "4px",
                                                    },
                                                    "&::-webkit-scrollbar-thumb:hover": {
                                                        background: "#2b2b2b",
                                                    },
                                                    overflow: "auto",
                                                }}
                                            >
                                                {!imap && !googleOauth ? (
                                                    <Container maxWidth="sm">
                                                        <StyledButton
                                                            sx={{
                                                                p: 2,
                                                                px: 2,
                                                                mt: 2,
                                                                mb: 4,
                                                            }}
                                                            onClick={() => setGoogleOauth(true)}
                                                            fullWidth
                                                        >
                                                            <Box>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                                                        <img
                                                                            src={google}
                                                                            alt="google"
                                                                            style={{ width: "40px" }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                color: "#9491AD",
                                                                            }}
                                                                        >
                                                                            Google
                                                                        </Typography>

                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: "700",
                                                                                color: "#000",
                                                                            }}
                                                                        >
                                                                            Google / G-Suite
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </StyledButton>

                                                        <StyledButton
                                                            sx={{
                                                                p: 2,
                                                                px: 2,
                                                                mt: 2,
                                                                mb: 4,
                                                            }}
                                                            // onClick={outlookHit}
                                                            onClick={newopen}
                                                            fullWidth
                                                        >
                                                            <Box>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                                                        <img
                                                                            src={outlook}
                                                                            alt="outlook"
                                                                            style={{ width: "50px" }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                color: "#9491AD",
                                                                            }}
                                                                        >
                                                                            Outlook
                                                                        </Typography>

                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: "700",
                                                                                color: "#000",
                                                                            }}
                                                                        >
                                                                            Outlook / Microsoft365
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </StyledButton>

                                                        <StyledButton
                                                            sx={{
                                                                p: 2,
                                                                mb: 2,
                                                            }}
                                                            onClick={() => setImap(true)}
                                                            fullWidth
                                                        >
                                                            <Box>
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                                                        <img src={imap_smtp} alt="imap_smtp" />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "12px",
                                                                                fontWeight: "500",
                                                                                color: "#9491AD",
                                                                            }}
                                                                        >
                                                                            Any provider
                                                                        </Typography>

                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: "700",
                                                                                color: "#000",
                                                                            }}
                                                                        >
                                                                            IMAP / SMTP
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </StyledButton>
                                                    </Container>
                                                ) : null}
                                                {imap ? (
                                                    <Box>
                                                        {imapSteps === 0 && (
                                                            <Box>
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            mb: 4,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={imap_smtp}
                                                                            alt="imap_smtp"
                                                                            sx={{ float: "left", width: "25px" }}
                                                                        />
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "18px",
                                                                                    fontWeight: "700",
                                                                                }}
                                                                            >
                                                                                Connect any provider
                                                                            </Typography>
                                                                            <Typography>IMAP / SMTP</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Typography
                                                                        align="center"
                                                                        color="primary"
                                                                        sx={{ fontWeight: "700", fontSize: "18px" }}
                                                                    >
                                                                        Select a connection option
                                                                    </Typography>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        sx={{
                                                                            p: 4,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={6}
                                                                            md={6}
                                                                            lg={6}
                                                                            xl={6}
                                                                            onClick={() =>
                                                                                imap_smptConnectTypeChangeHandler(
                                                                                    "ImapSmpt"
                                                                                )
                                                                            }
                                                                        >
                                                                            <StyledBox
                                                                                sx={{
                                                                                    borderRadius: "10px",
                                                                                    boxShadow:
                                                                                        "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                                                                    height: "fit-content",
                                                                                    width: "250px",
                                                                                    cursor: "pointer",
                                                                                    p: 4,
                                                                                    backgroundColor:
                                                                                        imapsmptConnectTypes ===
                                                                                        "ImapSmpt"
                                                                                            ? "#2196F3"
                                                                                            : "fff",
                                                                                    color:
                                                                                        imapsmptConnectTypes ===
                                                                                        "ImapSmpt"
                                                                                            ? "#fff"
                                                                                            : "000",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "700",
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    Option 1: Single Mailbox
                                                                                </Typography>
                                                                            </StyledBox>
                                                                        </Grid>

                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={6}
                                                                            md={6}
                                                                            lg={6}
                                                                            xl={6}
                                                                            onClick={handleOpenCsvFile}
                                                                        >
                                                                            <StyledBox
                                                                                sx={{
                                                                                    borderRadius: "10px",
                                                                                    boxShadow:
                                                                                        "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                                                                    height: "fit-content",
                                                                                    width: "250px",
                                                                                    cursor: "pointer",
                                                                                    p: 4,
                                                                                    backgroundColor:
                                                                                        uploadCsvFile === "Csvfile"
                                                                                            ? "#2196F3"
                                                                                            : "fff",
                                                                                    color:
                                                                                        uploadCsvFile === "Csvfile"
                                                                                            ? "#fff"
                                                                                            : "000",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "700",
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    Option 2: Multiple Mailbox
                                                                                </Typography>
                                                                            </StyledBox>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            </Box>
                                                        )}

                                                        {imapSteps === 1 && (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        mb: 4,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={imap_smtp}
                                                                        alt="imap_smtp"
                                                                        sx={{ float: "left", width: "25px" }}
                                                                    />
                                                                    <Box sx={{ ml: 2 }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "700",
                                                                            }}
                                                                        >
                                                                            Connect any provider
                                                                        </Typography>
                                                                        <Typography>IMAP / SMTP</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Container maxWidth="sm" sx={{ my: 2 }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                label="First Name"
                                                                                type="text"
                                                                                required={true}
                                                                                fullWidth
                                                                                value={firstName}
                                                                                onChange={(e) =>
                                                                                    setFirstName(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                label="Last Name"
                                                                                required
                                                                                fullWidth
                                                                                value={lastName}
                                                                                onChange={(e) =>
                                                                                    setLastName(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="email"
                                                                        label="Email"
                                                                        required
                                                                        onChange={(e) => {
                                                                            setSmtpUsername(e.target.value);
                                                                            setEmail(e.target.value);
                                                                        }}
                                                                        sx={{ my: 2 }}
                                                                    />
                                                                    <Button
                                                                        disabled={
                                                                            firstName === "" ||
                                                                            lastName === "" ||
                                                                            email === ""
                                                                        }
                                                                        variant="contained"
                                                                        fullWidth
                                                                        sx={{
                                                                            backgroundColor: "#38C68B",
                                                                            p: 1,
                                                                            mt: 2,
                                                                        }}
                                                                        onClick={imapForwardStepsHandler}
                                                                    >
                                                                        Next
                                                                    </Button>
                                                                </Container>
                                                            </>
                                                        )}

                                                        {imapSteps === 2 && (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        mb: 4,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={imap_smtp}
                                                                        alt="imap_smtp"
                                                                        sx={{ float: "left", width: "25px" }}
                                                                    />
                                                                    <Box sx={{ ml: 2 }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "700",
                                                                            }}
                                                                        >
                                                                            Connect to
                                                                        </Typography>
                                                                        <Typography>SMTP</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Container maxWidth="sm">
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Username"
                                                                        required
                                                                        value={smtpUsername}
                                                                        onChange={(e) =>
                                                                            setSmtpUsername(e.target.value)
                                                                        }
                                                                        sx={{}}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        type="password"
                                                                        label="SMTP Password"
                                                                        onChange={(e) =>
                                                                            setSmtpPassword(e.target.value)
                                                                        }
                                                                        required
                                                                        sx={{ my: 2 }}
                                                                    />
                                                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                required
                                                                                label="SMTP Host"
                                                                                value={smtpHost}
                                                                                fullWidth
                                                                                onChange={(e) =>
                                                                                    setSmtpHost(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                required
                                                                                label="SMTP Port"
                                                                                type="number"
                                                                                value={smtpPort}
                                                                                fullWidth
                                                                                onChange={(e) =>
                                                                                    setSmtpPort(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="warning"
                                                                            sx={{
                                                                                p: 1,
                                                                                mt: 2,
                                                                                mr: 2,
                                                                                color: "#481BEB",
                                                                                borderColor: "#E7E7E7",
                                                                                fontSize: "12px",
                                                                                fontFamily: "'Inter', sans-serif",
                                                                                "&:hover": {
                                                                                    borderColor: "#E7E7E7",
                                                                                },
                                                                            }}
                                                                            onClick={imapBackStepsHandler}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                        <Button
                                                                            disabled={
                                                                                buttonLoading ||
                                                                                smtpUsername === "" ||
                                                                                smtpPassword === "" ||
                                                                                smtpHost === "" ||
                                                                                smtpPort === null
                                                                            }
                                                                            variant="contained"
                                                                            sx={{
                                                                                backgroundColor: "#38C68B",
                                                                                p: 1,
                                                                                mt: 2,
                                                                            }}
                                                                            onClick={smtpDataCheckHandler}
                                                                        >
                                                                            {buttonLoading && (
                                                                                <CircularProgress size={25} />
                                                                            )}
                                                                            {!buttonLoading && "Next"}
                                                                        </Button>
                                                                    </Box>
                                                                </Container>
                                                            </>
                                                        )}

                                                        {imapSteps === 3 && (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        mb: 4,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={imap_smtp}
                                                                        alt="imap_smtp"
                                                                        sx={{ float: "left", width: "25px" }}
                                                                    />
                                                                    <Box sx={{ ml: 2 }}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "18px",
                                                                                fontWeight: "700",
                                                                            }}
                                                                        >
                                                                            Connect to
                                                                        </Typography>
                                                                        <Typography>IMAP</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Container maxWidth="sm">
                                                                    <TextField
                                                                        fullWidth
                                                                        type="email"
                                                                        label="Email"
                                                                        required
                                                                        value={email}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }}
                                                                        sx={{}}
                                                                    />
                                                                    <TextField
                                                                        fullWidth
                                                                        type="password"
                                                                        label="Password"
                                                                        required
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        sx={{ my: 2 }}
                                                                    />
                                                                    <Grid container spacing={2}>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                required
                                                                                label="IMAP Host"
                                                                                fullWidth
                                                                                value={imapHost}
                                                                                onChange={(e) =>
                                                                                    setImapHost(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={12}
                                                                            lg={6}
                                                                            xl={6}
                                                                        >
                                                                            <TextField
                                                                                required
                                                                                label="IMAP Port"
                                                                                type="number"
                                                                                value={imapPort}
                                                                                fullWidth
                                                                                onChange={(e) =>
                                                                                    setImapPort(e.target.value)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="warning"
                                                                            sx={{ p: 1, mt: 2, mr: 2 }}
                                                                            onClick={imapBackStepsHandler}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                        <Button
                                                                            disabled={
                                                                                buttonLoading ||
                                                                                email === "" ||
                                                                                password === "" ||
                                                                                imapHost === "" ||
                                                                                imapPort === null
                                                                            }
                                                                            variant="contained"
                                                                            sx={{
                                                                                backgroundColor: "#38C68B",
                                                                                p: 1,
                                                                                mt: 2,
                                                                            }}
                                                                            onClick={addNewDataSubmitHandler}
                                                                        >
                                                                            {buttonLoading && (
                                                                                <CircularProgress size={25} />
                                                                            )}
                                                                            {!buttonLoading && "Submit"}
                                                                        </Button>
                                                                    </Box>
                                                                </Container>
                                                            </>
                                                        )}
                                                    </Box>
                                                ) : // <Box sx={{ height: 'auto', width: "auto", p: 2 }}>
                                                //     <TextField
                                                //         required
                                                //         label='Email'
                                                //         type='email'
                                                //         fullWidth
                                                //         onChange={(e) => setEmail(e.target.value)}
                                                //         sx={{ mb: 2 }}
                                                //     />
                                                //     <TextField
                                                //         required
                                                //         label='Password'
                                                //         type='password'
                                                //         fullWidth
                                                //         onChange={(e) => setPassword(e.target.value)}
                                                //         sx={{ mb: 2 }}
                                                //     />
                                                //     <Grid container spacing={2} sx={{ mb: 2 }}>
                                                //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                //             <TextField
                                                //                 required
                                                //                 label='SMTP Host'
                                                //                 fullWidth
                                                //                 onChange={(e) => setSmtpHost(e.target.value)}
                                                //             />
                                                //         </Grid>
                                                //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                //             <TextField
                                                //                 required
                                                //                 label='SMTP Port'
                                                //                 type='number'
                                                //                 fullWidth
                                                //                 onChange={(e) => setSmtpPort(e.target.value)}
                                                //             />
                                                //         </Grid>
                                                //     </Grid>
                                                //     <Grid container spacing={2}>
                                                //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                //             <TextField
                                                //                 required
                                                //                 label='IMAP Host'
                                                //                 fullWidth
                                                //                 onChange={(e) => setImapHost(e.target.value)}
                                                //             />
                                                //         </Grid>
                                                //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                //             <TextField
                                                //                 required
                                                //                 label='IMAP Port'
                                                //                 type='number'
                                                //                 fullWidth
                                                //                 onChange={(e) => setImapPort(e.target.value)}
                                                //             />
                                                //         </Grid>
                                                //     </Grid>
                                                null}
                                                {googleOauth ? (
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                mb: 4,
                                                            }}
                                                        >
                                                            <img src={google} alt="google" style={{ width: "40px" }} />
                                                            <Box sx={{ ml: 2 }}>
                                                                <Typography
                                                                    sx={{ fontSize: "18px", fontWeight: "700" }}
                                                                >
                                                                    Connect your google account
                                                                </Typography>
                                                                <Typography>Gmail / G-suite</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            {googleOauthSteps === 0 && (
                                                                <Container maxWidth="sm">
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{ fontSize: "16px", fontWeight: 700 }}
                                                                        >
                                                                            First, let's enable IMAP access for your
                                                                            Google account.
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
                                                                        <ol>
                                                                            <li>On your computer, open Gmail.</li>
                                                                            <li>
                                                                                Click the gear icon in the top right
                                                                                corner
                                                                            </li>
                                                                            <li>Click all settings</li>
                                                                            <li>
                                                                                Click the{" "}
                                                                                <a
                                                                                    href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                                                                                    style={{
                                                                                        color: "blue",
                                                                                        textDecoration: "none",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    Forwarding and POP/IMAP
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                In the "IMAP access" section, select
                                                                                Enable IMAP.
                                                                            </li>
                                                                            <li>Click Save Changes.</li>
                                                                        </ol>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={googleForwardStepsHandler}
                                                                        >
                                                                            IMAP has been enabled
                                                                        </Button>
                                                                    </Box>
                                                                </Container>
                                                            )}

                                                            {googleOauthSteps === 1 && (
                                                                <>
                                                                    <Typography
                                                                        align="center"
                                                                        color="primary"
                                                                        sx={{ fontWeight: "700", fontSize: "18px" }}
                                                                    >
                                                                        Select a connection option
                                                                    </Typography>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        sx={{
                                                                            p: 4,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={6}
                                                                            md={6}
                                                                            lg={6}
                                                                            xl={6}
                                                                            onClick={() =>
                                                                                googleConnectTypeChangeHandler("oAuth")
                                                                            }
                                                                        >
                                                                            <StyledBox
                                                                                sx={{
                                                                                    borderRadius: "10px",
                                                                                    boxShadow:
                                                                                        "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                                                                    height: "fit-content",
                                                                                    width: "250px",
                                                                                    cursor: "pointer",
                                                                                    p: 4,
                                                                                    backgroundColor:
                                                                                        googleConnectTypes === "oAuth"
                                                                                            ? "#2196F3"
                                                                                            : "fff",
                                                                                    color:
                                                                                        googleConnectTypes === "oAuth"
                                                                                            ? "#fff"
                                                                                            : "000",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "700",
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    Option 1: oAuth
                                                                                </Typography>
                                                                                <Box>
                                                                                    <Stack sx={{}} spacing={2}>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <CheckCircleIcon
                                                                                                sx={{ mr: 1 }}
                                                                                            />
                                                                                            Easier to setup
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <CheckCircleIcon
                                                                                                sx={{ mr: 1 }}
                                                                                            />
                                                                                            More stable and less
                                                                                            disconnects
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <CheckCircleIcon
                                                                                                sx={{ mr: 1 }}
                                                                                            />
                                                                                            Available for GSuite
                                                                                            accounts
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        mt: 2,
                                                                                    }}
                                                                                >
                                                                                    <Chip
                                                                                        label="Recommended"
                                                                                        sx={{
                                                                                            backgroundColor: "#38C68B",
                                                                                            color: "#fff",
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </StyledBox>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sm={6}
                                                                            md={6}
                                                                            lg={6}
                                                                            xl={6}
                                                                            onClick={() =>
                                                                                googleConnectTypeChangeHandler(
                                                                                    "app-password"
                                                                                )
                                                                            }
                                                                        >
                                                                            <StyledBox
                                                                                sx={{
                                                                                    borderRadius: "10px",
                                                                                    boxShadow:
                                                                                        "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                                                                    height: "fit-content",
                                                                                    width: "250px",
                                                                                    cursor: "pointer",
                                                                                    p: 4,
                                                                                    backgroundColor:
                                                                                        googleConnectTypes !== "oAuth"
                                                                                            ? "#2196F3"
                                                                                            : "fff",
                                                                                    color:
                                                                                        googleConnectTypes !== "oAuth"
                                                                                            ? "#fff"
                                                                                            : "000",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "700",
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    Option 2: App Password
                                                                                </Typography>
                                                                                <Box>
                                                                                    <Stack sx={{}} spacing={2}>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <CheckCircleIcon
                                                                                                sx={{ mr: 1 }}
                                                                                            />
                                                                                            Available for personal
                                                                                            accounts
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <ErrorIcon
                                                                                                sx={{ mr: 1 }}
                                                                                                color="warning"
                                                                                            />
                                                                                            Require 2-factor
                                                                                            authentication
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <ErrorIcon
                                                                                                sx={{ mr: 1 }}
                                                                                                color="warning"
                                                                                            />
                                                                                            More prone to disconnects
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Box>
                                                                            </StyledBox>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{ float: "left" }}
                                                                            onClick={googleBackStepsHandler}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{ float: "right" }}
                                                                            onClick={googleForwardStepsHandler}
                                                                        >
                                                                            Next
                                                                        </Button>
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {googleOauthSteps === 2 && (
                                                                <Container maxWidth="sm">
                                                                    {googleConnectTypes === "oAuth" && (
                                                                        <>
                                                                            <Box sx={{ mb: 2 }}>
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "700",
                                                                                        color: "#555",
                                                                                    }}
                                                                                >
                                                                                    Allow xCampaign to access your
                                                                                    Google workspace
                                                                                </Typography>
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                    color="primary"
                                                                                >
                                                                                    <a
                                                                                        target="blank"
                                                                                        href="https://youtu.be/KOvdBoHCxoM"
                                                                                    >
                                                                                        See tutorial video
                                                                                    </a>
                                                                                </Typography>
                                                                                <Typography
                                                                                    align="center"
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                    color="primary"
                                                                                >
                                                                                    <a
                                                                                        target="blank"
                                                                                        href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps"
                                                                                    >
                                                                                        Allow Access Link
                                                                                    </a>
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "500",
                                                                                        my: 1,
                                                                                    }}
                                                                                >
                                                                                    1: Go to your Google Workspace Admin
                                                                                    Panel
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "500",
                                                                                    }}
                                                                                >
                                                                                    2: Click "Add App" and then select
                                                                                    "OAuth App Name or Client ID"
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "16px",
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                >
                                                                                    3: Use the following Client-ID to
                                                                                    search for xCampaign:
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    position: "relative",
                                                                                    backgroundColor: "#eeeeee",
                                                                                    // height: "50px",
                                                                                    // display: "flex",
                                                                                    // alignItems: "center",
                                                                                    borderRadius: "10px",
                                                                                    wordWrap: "break-word",
                                                                                    p: 2,
                                                                                    my: 2,
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    sx={{
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                        fontSize: "12px",
                                                                                    }}
                                                                                    onClick={clickToCopy}
                                                                                >
                                                                                    Copy Text
                                                                                </Button>
                                                                                <span>{REACT_APP_GOOGLEAUTH_KEY}</span>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    mt: 2,
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    variant="contained"
                                                                                    onClick={googleBackStepsHandler}
                                                                                    sx={{ mr: 2 }}
                                                                                    color="info"
                                                                                >
                                                                                    Back
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={googleOauthHandler(true)}
                                                                                    variant="contained"
                                                                                    disabled={buttonLoading}
                                                                                    sx={{ backgroundColor: "#38C68B" }}
                                                                                >
                                                                                    {buttonLoading && (
                                                                                        <CircularProgress size={25} />
                                                                                    )}
                                                                                    {!buttonLoading && "Connect"}
                                                                                </Button>
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                    {googleConnectTypes !== "oAuth" && (
                                                                        <>
                                                                            <Grid container spacing={2}>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={6}
                                                                                    xl={6}
                                                                                >
                                                                                    <StyledTextField
                                                                                        label="First Name"
                                                                                        value={firstName}
                                                                                        fullWidth
                                                                                        onChange={(e) =>
                                                                                            setFirstName(e.target.value)
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={6}
                                                                                    xl={6}
                                                                                >
                                                                                    <StyledTextField
                                                                                        label="Last Name"
                                                                                        value={lastName}
                                                                                        fullWidth
                                                                                        onChange={(e) =>
                                                                                            setLastName(e.target.value)
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <StyledTextField
                                                                                fullWidth
                                                                                type="email"
                                                                                label="Email"
                                                                                value={email}
                                                                                onChange={(e) => {
                                                                                    setSmtpUsername(e.target.value);
                                                                                    setEmail(e.target.value);
                                                                                }}
                                                                                sx={{ my: 2 }}
                                                                            />
                                                                            <StyledTextField
                                                                                fullWidth
                                                                                type="password"
                                                                                value={password}
                                                                                onChange={(e) => {
                                                                                    setSmtpPassword(e.target.value);
                                                                                    setPassword(e.target.value);
                                                                                }}
                                                                                label="App Password"
                                                                            />
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    mt: 2,
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    variant="contained"
                                                                                    onClick={googleBackStepsHandler}
                                                                                    sx={{ mr: 2 }}
                                                                                    color="info"
                                                                                >
                                                                                    Back
                                                                                </Button>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    disabled={
                                                                                        buttonLoading ||
                                                                                        email === "" ||
                                                                                        firstName === "" ||
                                                                                        lastName === ""
                                                                                    }
                                                                                    onClick={addNewDataSubmitHandler}
                                                                                    sx={{ backgroundColor: "#38C68B" }}
                                                                                >
                                                                                    {buttonLoading && (
                                                                                        <CircularProgress size={25} />
                                                                                    )}
                                                                                    {!buttonLoading && "Connect"}
                                                                                </Button>
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                </Container>
                                                            )}
                                                        </Box>
                                                        {/* <Box>
                                                            Allow xCampaign to access your Google workspace
                                                            <a href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps">See tutorial video</a>
                                                            Go to your Google Workspace Admin Panel
                                                            Click "Add App" and then select "OAuth App Name or Client ID"
                                                        </Box>
                                                        <Box>Use the following Client-ID to search for xCampaign:</Box>
                                                        <Box sx={{ backgroundColor: "#eeeeee", height: "50px", display: "flex", alignItems: "center", borderRadius: "10px", p: 2 }}>
                                                            {REACT_APP_GOOGLEAUTH_KEY}
                                                        </Box>
                                                        <Button onClick={googleOauthHandler}>Connect</Button> */}
                                                    </Box>
                                                ) : null}
                                            </DialogContent>
                                            <DialogActions
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {(imap || googleOauth) && (
                                                    <Box
                                                        sx={{
                                                            width: "100%",
                                                            fontWeight: "700",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        <IconButton onClick={handleSelectAnotherProvider}>
                                                            <ArrowBackIcon />
                                                        </IconButton>
                                                        Select another provider
                                                    </Box>
                                                )}
                                                <Box sx={{ my: 3 }}>
                                                    <Button
                                                        sx={{
                                                            py: 1,
                                                            color: "#481BEB",
                                                            borderColor: "#E7E7E7",
                                                            fontSize: "12px",
                                                            fontFamily: "'Inter', sans-serif",
                                                            "&:hover": {
                                                                borderColor: "#E7E7E7",
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            </DialogActions>
                                        </Box>
                                    </Dialog>
                                </>
                            </Stack>
                        </Box>
                        <Box>
                            <>
                                {csvfileLoader ? (
                                    <CircularProgress sx={{ marginLeft: "45%", marginTop: "15%" }} size={40} />
                                ) : CsvRecords.length === 0 ? (
                                    <div>
                                        <StyledTableContainer>
                                            <Table
                                                sx={{
                                                    minWidth: 650,
                                                    borderCollapse: "separate",
                                                    borderSpacing: "0 10px",
                                                    mt: 2,
                                                }}
                                                aria-label="simple table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <Checkbox onChange={(e) => handleSelectAll(e)}></Checkbox>
                                                        </StyledTableCell>
                                                        <StyledTableCell>EMAIL</StyledTableCell>
                                                        <StyledTableCell>STATUS</StyledTableCell>
                                                        <StyledTableCell>SENT</StyledTableCell>
                                                        <StyledTableCell>INBOX</StyledTableCell>
                                                        <StyledTableCell>SPAM</StyledTableCell>
                                                        <StyledTableCell>REPLIED</StyledTableCell>
                                                        <StyledTableCell>HEALTH</StyledTableCell>
                                                        <StyledTableCell align="center">ACTION</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {currentRecords &&
                                                        currentRecords.map((row, index) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell
                                                                    sx={{
                                                                        fontSize: "16px !important",
                                                                        fontWeight: "700 !important",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={bulkData.includes(row.sender_email)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setbulkData((previousData) => [
                                                                                    ...previousData,
                                                                                    row.sender_email,
                                                                                ]);
                                                                            } else {
                                                                                setbulkData((previousData) =>
                                                                                    previousData.filter(
                                                                                        (selectedData) =>
                                                                                            selectedData !==
                                                                                            row.sender_email
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                    ></Checkbox>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    sx={{
                                                                        fontSize: "16px !important",
                                                                        fontWeight: "700 !important",
                                                                    }}
                                                                >
                                                                    {row["sender_email"]}
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <Chip
                                                                        sx={{
                                                                            fontWeight: "600",
                                                                            fontSize: "12px",
                                                                            fontFamily: "'Inter', sans-serif",
                                                                            color: "#fff",
                                                                            backgroundColor:
                                                                                tableData?.warmup_setting[
                                                                                    getSettingIndex(
                                                                                        row["sender_idsender"]
                                                                                    )
                                                                                ].enable === 1 &&
                                                                                tableData?.warmup_setting[
                                                                                    getSettingIndex(
                                                                                        row["sender_idsender"]
                                                                                    )
                                                                                ].errors === 0
                                                                                    ? "#38C68B"
                                                                                    : tableData?.warmup_setting[
                                                                                            getSettingIndex(
                                                                                                row["sender_idsender"]
                                                                                            )
                                                                                        ].enable === 0 &&
                                                                                        tableData?.warmup_setting[
                                                                                            getSettingIndex(
                                                                                                row["sender_idsender"]
                                                                                            )
                                                                                        ].errors === 0
                                                                                      ? "#9491AD"
                                                                                      : "#EF6D6D",
                                                                        }}
                                                                        label={
                                                                            tableData?.warmup_setting[
                                                                                getSettingIndex(row["sender_idsender"])
                                                                            ].enable === 1 &&
                                                                            tableData?.warmup_setting[
                                                                                getSettingIndex(row["sender_idsender"])
                                                                            ].errors === 0
                                                                                ? "Active"
                                                                                : tableData?.warmup_setting[
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    ].enable === 0 &&
                                                                                    tableData?.warmup_setting[
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    ].errors === 0
                                                                                  ? "Paused"
                                                                                  : "Error"
                                                                        }
                                                                        color={
                                                                            tableData?.warmup_setting[
                                                                                getSettingIndex(row["sender_idsender"])
                                                                            ].enable === 1 &&
                                                                            tableData?.warmup_setting[
                                                                                getSettingIndex(row["sender_idsender"])
                                                                            ].errors === 0
                                                                                ? "success"
                                                                                : tableData?.warmup_setting[
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    ].enable === 0 &&
                                                                                    tableData?.warmup_setting[
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    ].errors === 0
                                                                                  ? "default"
                                                                                  : "error"
                                                                        }
                                                                    />
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            gap: "5px",
                                                                        }}
                                                                    >
                                                                        <SendIcon
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                color: "#BEBEBE",
                                                                                marginTop: "4px",
                                                                                transform:
                                                                                    "rotate(-45deg)translateY(-0.3rem)",
                                                                            }}
                                                                        />
                                                                        {row["email_sent"]}
                                                                    </div>
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <InboxIcon
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                color: "#BEBEBE",
                                                                            }}
                                                                        />
                                                                        {row["inbox"]}
                                                                    </div>
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <ErrorIcon
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                color: "#BEBEBE",
                                                                            }}
                                                                        />
                                                                        {row["spam"]}
                                                                    </div>
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "4px",
                                                                        }}
                                                                    >
                                                                        <ReplyIcon
                                                                            sx={{
                                                                                fontSize: "16px",
                                                                                color: "#BEBEBE",
                                                                                transform: "rotateY(180deg)",
                                                                            }}
                                                                        />
                                                                        {row["replied"]}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {row["email_sent"] === 0 ? (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                color: "#BEBEBE",
                                                                            }}
                                                                        >
                                                                            <StopRoundedIcon
                                                                                sx={{ fontSize: "30px" }}
                                                                            />
                                                                            NA
                                                                        </div>
                                                                    ) : (
                                                                        (() => {
                                                                            const spamPercentage = Math.round(
                                                                                (row["spam"] * 100) / row["email_sent"]
                                                                            );
                                                                            let statusColor, statusText;
                                                                            if (spamPercentage > 15) {
                                                                                statusColor = "#EF6D6D";
                                                                                statusText = "Bad";
                                                                            } else if (
                                                                                spamPercentage >= 5 &&
                                                                                spamPercentage <= 15
                                                                            ) {
                                                                                statusColor = "#EFA10D";
                                                                                statusText = "Fair";
                                                                            } else {
                                                                                statusColor = "#38C68B";
                                                                                statusText = "Good";
                                                                            }
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        color: statusColor,
                                                                                    }}
                                                                                >
                                                                                    <StopRoundedIcon
                                                                                        sx={{ fontSize: "30px" }}
                                                                                    />
                                                                                    {statusText}
                                                                                </div>
                                                                            );
                                                                        })()
                                                                    )}
                                                                </StyledTableCell>

                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Tooltip title="Play/Pause Button">
                                                                            <IconButton
                                                                                disabled={
                                                                                    tableData?.warmup_setting[
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    ].errors >= 1
                                                                                }
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    toggleCronjobSetting(
                                                                                        tableData?.warmup_setting[
                                                                                            getSettingIndex(
                                                                                                row["sender_idsender"]
                                                                                            )
                                                                                        ].sender_email,
                                                                                        tableData?.warmup_setting[
                                                                                            getSettingIndex(
                                                                                                row["sender_idsender"]
                                                                                            )
                                                                                        ].enable,
                                                                                        getSettingIndex(
                                                                                            row["sender_idsender"]
                                                                                        )
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {tableData?.warmup_setting[
                                                                                    getSettingIndex(
                                                                                        row["sender_idsender"]
                                                                                    )
                                                                                ].enable === 0 ||
                                                                                tableData?.warmup_setting[
                                                                                    getSettingIndex(
                                                                                        row["sender_idsender"]
                                                                                    )
                                                                                ].errors >= 1 ? (
                                                                                    <PlayCircleFilledWhiteIcon />
                                                                                ) : (
                                                                                    <PauseCircleIcon />
                                                                                )}
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <IconButton
                                                                            onClick={() => {
                                                                                toggleSettingDrawerOpen(
                                                                                    row["sender_idsender"]
                                                                                );
                                                                                fetchWarmupSetting(row["sender_email"]);
                                                                            }}
                                                                        >
                                                                            <SettingsIcon sx={{ color: "#989898" }} />
                                                                        </IconButton>
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </StyledTableContainer>

                                        <div className="pagination">
                                            <button
                                                style={{
                                                    color: currentPage === 1 ? "#aaa" : "#004AAD",
                                                    border: currentPage === 1 ? "#aaa" : "#004AAD",
                                                    cursor: currentPage === 1 ? "not-allowed" : "pointer",
                                                }}
                                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                disabled={currentPage === 1}
                                            >
                                                Previous
                                            </button>
                                            {renderPageNumbers()}
                                            <button
                                                style={{
                                                    color: currentPage === totalPages ? "#aaa" : "#004AAD",
                                                    border: currentPage === totalPages ? "#aaa" : "#004AAD",
                                                    cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                                                }}
                                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </button>
                                        </div>
                                        <div>
                                            <style jsx>{`
                                                .pagination {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    gap: 5px;
                                                    margin-top: 20px;
                                                }
                                                .pagination-button {
                                                    background-color: #f0f0f0;
                                                    border: 1px solid #ccc;
                                                    padding: 8px 12px;
                                                    border-radius: 5px;
                                                    cursor: pointer;
                                                    transition:
                                                        background-color 0.3s,
                                                        color 0.3s;
                                                }
                                                .pagination-button:disabled {
                                                    background-color: #e0e0e0;
                                                    cursor: not-allowed;
                                                }
                                                .pagination-button:not(:disabled):hover {
                                                    background-color: #007bff;
                                                    color: #fff;
                                                }
                                                .page-button {
                                                    background-color: #f0f0f0;
                                                    border: 1px solid #ccc;
                                                    padding: 8px 12px;
                                                    border-radius: 5px;
                                                    cursor: pointer;
                                                    transition:
                                                        background-color 0.3s,
                                                        color 0.3s;
                                                }
                                                .page-button.active {
                                                    background-color: #007bff;
                                                    color: #fff;
                                                    border: 1px solid #007bff;
                                                }
                                                .page-button:not(.active):hover {
                                                    background-color: #007bff;
                                                    color: #fff;
                                                }
                                                .ellipsis {
                                                    padding: 8px 12px;
                                                }
                                                .search-input {
                                                    padding: 10px;
                                                    width: 100%;
                                                    max-width: 300px;
                                                    margin-bottom: 20px;
                                                    border-radius: 5px;
                                                    border: 1px solid #ccc;
                                                }
                                                .record-item {
                                                    list-style: none;
                                                    padding: 8px;
                                                    border-bottom: 1px solid #ccc;
                                                }
                                            `}</style>
                                        </div>

                                        {/* {
                      <div style={{ marginTop: "13px" }}>
                        <Stack
                          spacing={2}
                          sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Pagination
                            count={totalpage}
                            color="primary"
                            onChange={handleChanges}
                            page={currentpage}
                          />
                        </Stack>
                      </div>
                    } */}
                                    </div>
                                ) : (
                                    <div style={{ marginTop: "10px" }}>
                                        <ArrowBackIcon
                                            onClick={backfunction}
                                            sx={{ cursor: "pointer" }}
                                        ></ArrowBackIcon>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginRight: "52px",
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "20px", ml: "55px" }}>RECORDS</Typography>
                                        </div>

                                        <>
                                            <StyledTableContainer>
                                                <Table
                                                    sx={{
                                                        minWidth: 650,
                                                        borderCollapse: "separate",
                                                        borderSpacing: "0 10px",
                                                        mt: 2,
                                                    }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>Name</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell>TotalCount</StyledTableCell>
                                                            <StyledTableCell>SuccessfulCount</StyledTableCell>
                                                            <StyledTableCell>FailureCount</StyledTableCell>
                                                            <StyledTableCell>Error File</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {csvdata.map((data, index) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell
                                                                    sx={{
                                                                        fontSize: "16px !important",
                                                                        fontWeight: "500 !important",
                                                                    }}
                                                                >
                                                                    {data.name}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "5px",
                                                                        }}
                                                                    >
                                                                        {data.status}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px",
                                                                            marginLeft: "10px",
                                                                        }}
                                                                    >
                                                                        {data.totalCount}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px",
                                                                            marginLeft: "30px",
                                                                        }}
                                                                    >
                                                                        {data.successfulCount}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px",
                                                                            marginLeft: "25px",
                                                                        }}
                                                                    >
                                                                        {data.failureCount}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px",
                                                                            marginLeft: "25px",
                                                                        }}
                                                                    >
                                                                        {data.failureCount == 0 ? (
                                                                            <FileDownloadIcon
                                                                                style={{
                                                                                    cursor: "not-allowed",
                                                                                    color: "pink",
                                                                                    opacity: 0.5,
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <FileDownloadIcon
                                                                                onClick={downloadFile}
                                                                                style={{ cursor: "pointer" }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </StyledTableContainer>
                                        </>
                                    </div>
                                )}
                            </>

                            {/* <section>
                <Stack>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                </Stack>
              </section> */}

                            <Snackbar
                                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                                open={successSnackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleSuccessSnackbarClose}
                            >
                                <Alert
                                    onClose={handleSuccessSnackbarClose}
                                    variant="filled"
                                    severity="success"
                                    sx={{ width: "100%" }}
                                >
                                    {addNewSuccessMessage}
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                                open={errorSnackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleErrorSnackbarClose}
                            >
                                <Alert
                                    onClose={handleErrorSnackbarClose}
                                    variant="filled"
                                    severity="error"
                                    sx={{ width: "100%" }}
                                >
                                    {addNewErrorMessage}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Box>
                </>
            )}

            {settingDrawer && (
                <>
                    <Box>
                        <StyledButtonOutlined
                            onClick={() => {
                                setSettingDrawer(false);
                                setNewDataAdd(!newDataAdd);
                            }}
                            color="warning"
                            variant="outlined"
                            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                        >
                            <ArrowBackIcon />
                            Exit without saving
                        </StyledButtonOutlined>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#F7F7F9",
                            mt: 6,
                            width: "100%",
                            borderRadius: "10px",
                            p: 1.5,
                        }}
                    >
                        <Stack
                            flexWrap="wrap"
                            direction="row"
                            justifyContent={{
                                lg: "space-between",
                                md: "space-between",
                                sm: "center",
                            }}
                            gap="20px"
                            sx={{}}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                sx={{
                                    display: "flex",
                                    // justifyContent: "start",
                                    alignItems: "center",
                                }}
                            >
                                <Avatar
                                    sx={{
                                        borderRadius: "16px",
                                        backgroundColor: "#7860DA",
                                        float: "left",
                                        mr: 2,
                                        pt: 0.7,
                                    }}
                                >
                                    G
                                </Avatar>
                                <Typography
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: "500",
                                        float: "left",
                                        mr: 2,
                                    }}
                                >
                                    {settingDrawerData?.sender_email}
                                </Typography>
                                <Chip
                                    label={
                                        <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                                            {settingDrawerData.enable === 1 && settingDrawerData.errors === 0
                                                ? "Active"
                                                : settingDrawerData.enable === 0 && settingDrawerData.errors === 0
                                                  ? "Paused"
                                                  : "Error"}
                                        </Typography>
                                    }
                                    color={
                                        settingDrawerData.enable === 1 && settingDrawerData.errors === 0
                                            ? "success"
                                            : settingDrawerData.enable === 0 && settingDrawerData.errors === 0
                                              ? "default"
                                              : "error"
                                    }
                                    sx={{
                                        width: "64px",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        fontFamily: "'Inter', sans-serif",
                                        color: "#fff",
                                        backgroundColor:
                                            settingDrawerData.enable === 1 && settingDrawerData.errors === 0
                                                ? "#38C68B"
                                                : settingDrawerData.enable === 0 && settingDrawerData.errors === 0
                                                  ? "#9491AD"
                                                  : "#EF6D6D",
                                    }}
                                />
                                <Grid>
                                    {visible && (
                                        <InfoIcon
                                            sx={{ marginLeft: "20px" }}
                                            onClick={(e) => {
                                                getStatusDetails(settingDrawerData.sender_email);
                                            }}
                                        ></InfoIcon>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                sx={{
                                    display: "flex",
                                    // justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#181818" }}>
                                    Activities
                                </Typography>
                                {[
                                    { 0: "email_sent", 1: "Sent", 3: "#38C68B" },
                                    { 0: "inbox", 1: "Inbox", 3: "#5570E8" },
                                    { 0: "spam", 1: "Spam", 3: "#EF6D6D" },
                                    { 0: "replied", 1: "Health", 3: "#38C68B" },
                                ].map((func, ind) => (
                                    <Card
                                        key={func[1]}
                                        sx={{
                                            boxShadow: "none",
                                            borderRadius: "6px",
                                            mx: 1,
                                            px: 2.5,
                                            py: 1.5,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {func[1] !== "Health" ? (
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    color: func[3],
                                                }}
                                            >
                                                {settingAnalyticsData[func[0]]}
                                            </Typography>
                                        ) : (
                                            <IconWithText text={func[1]} color={func[3]} />
                                        )}
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#7D7D7D",
                                            }}
                                        >
                                            {func[1]}
                                        </Typography>
                                    </Card>
                                ))}
                            </Grid>
                        </Stack>
                    </Box>

                    <Box sx={{ width: "100%", mt: 1 }}>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#272727",
                                float: "left",
                                mr: 1,
                            }}
                        >
                            Account added on:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#989898",
                                float: "left",
                            }}
                        >
                            {createdAtDate}
                        </Typography>
                    </Box>
                    <Grid container sx={{ pt: 3 }}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pr: 5 }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "700", pb: 3 }}>Edit Account</Typography>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>First Name*</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        placeholder="First Name"
                                        value={settingProfileData.fname}
                                        onChange={(e) => {
                                            let tempSettingData = settingProfileData;
                                            tempSettingData.fname = e.target.value;
                                            setSettingProfileData(tempSettingData);
                                            setChange(!change);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>Last Name</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        placeholder="Last Name"
                                        value={settingProfileData.lname}
                                        onChange={(e) => {
                                            let tempSettingData = settingProfileData;
                                            tempSettingData.lname = e.target.value;
                                            setSettingProfileData(tempSettingData);
                                            setChange(!change);
                                        }}
                                    />
                                </Grid>
                                {settingProfileData ? null : settingProfileData.lname}
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography sx={{ display: "flex" }}>
                                        <Typography>Starting Volume* (1-10)</Typography>
                                        <LightTooltip
                                            title={
                                                <span style={{ color: "black" }}>
                                                    {" "}
                                                    Once added, it cannot be edited. To make changes, disconnect the
                                                    mailbox & add it again.
                                                </span>
                                            }
                                        >
                                            <InfoIcon />
                                        </LightTooltip>
                                    </StyledTypography>
                                    {isVolumeDisabled == 0 ? (
                                        <StyledTextField
                                            onChange={handleStartingVolume}
                                            fullWidth
                                            required
                                            type="number"
                                            value={settingDrawerData?.starting_volume}
                                            inputProps={{
                                                min: "1",
                                                max: "10",
                                            }}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                                "& input[type=number]": {
                                                    MozAppearance: "textfield",
                                                },
                                            }}
                                        />
                                    ) : (
                                        <StyledTextField
                                            disabled={isVolumeDisabled}
                                            fullWidth
                                            required
                                            type="number"
                                            value={settingDrawerData?.starting_volume}
                                            inputProps={{
                                                min: "1",
                                                max: "10",
                                            }}
                                            sx={{
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                    {
                                                        display: "none",
                                                    },
                                                "& input[type=number]": {
                                                    MozAppearance: "textfield",
                                                },
                                            }}
                                        />
                                    )}
                                    {/* <StyledTextField

                    fullWidth
                    required
                    disabled={isVolumeDisabled}
                    onChange={handleStartingVolume}
                    type="number"
                    value={settingDrawerData?.starting_volume}
                    inputProps={{
                      min: "1",
                      max: "10",
                    }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      background: '#d1d1d1'
                    }}
                  /> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>{`Ending Volume (1-${mailLimit})`}</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        type="number"
                                        value={settingDrawerData?.max_par_day}
                                        inputProps={{
                                            min: "1",
                                            max: mailLimit,
                                        }}
                                        onChange={handleEndingingVolume}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                            "& input[type=number]": {
                                                MozAppearance: "textfield",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>Reply Percent* (0-50)</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        value={settingDrawerData?.reply_rate}
                                        type="number"
                                        inputProps={{
                                            min: "0",
                                            max: "50",
                                        }}
                                        onChange={handleReplyPercentage}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                            "& input[type=number]": {
                                                MozAppearance: "textfield",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>Increase per day* (1-50)</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        value={settingDrawerData?.increase_per_day}
                                        type="number"
                                        onChange={handleIncreasePerDay}
                                        inputProps={{
                                            min: "1",
                                            max: "50",
                                        }}
                                        sx={{
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none",
                                            },
                                            "& input[type=number]": {
                                                MozAppearance: "textfield",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <StyledTypography>Warmup Tag</StyledTypography>
                                    <StyledTextField
                                        fullWidth
                                        required
                                        placeholder="Tag Number"
                                        value={settingDrawerData?.filter_tag}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <StyledTypography>Sender Campaign Limit*(50-300)</StyledTypography>
                    <StyledTextField
                      fullWidth
                      required
                      value={settingDrawerData?.sender_max_limit}
                      onChange={handleSenderMaxLimit}
                      type="number"
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Grid> */}
                            </Grid>

                            {/* <StyledTextField
                  fullWidth
                  required
                  value={settingDrawerData?.custom_tracking}
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            sx={{ pl: 5, borderLeft: "1px solid #E7E7E7" }}
                        >
                            {/* <StyledTypography sx={{ fontSize: "16px", fontWeight: "700", pb: 3 }}>
                  Preference
                </StyledTypography> */}
                            {/* <Card
                  sx={{
                    p: 2,
                    boxShadow: '0px 2px 11px 0px #00000012',
                    borderRadius: '10px',
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    Perform Deliverability Test for this domain
                  </StyledTypography>
                  <Switch color="success" sx={{ ml: "auto", color: '#38C68B' }} />
                </Card> */}

                            <Card
                                sx={{
                                    p: 2,
                                    boxShadow: "0px 2px 11px 0px #00000012",
                                    borderRadius: "10px",
                                    mb: 2,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                    Total mail Limit : {mailLimit}
                                </StyledTypography>
                                <StyledTypography sx={{ fontSize: "14px", fontWeight: "600", ml: 5 }}>
                                    Remaining mail Limit : {remainLimit}
                                </StyledTypography>
                            </Card>
                            <Card>
                                <CardContent>ESP</CardContent>
                                {/* Flexbox container for all checkboxes */}

                                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                    {espOptions.map((esp) =>
                                        esp.key !== "all" && espVisibility[esp.key]?.visibility ? (
                                            <div key={esp.key} style={{ margin: "0 10px" }}>
                                                <Checkbox
                                                    checked={selectedValues.includes(esp.key)}
                                                    onChange={() => handleCheckboxChange(esp.key)}
                                                />{" "}
                                                {esp.label}
                                            </div>
                                        ) : null
                                    )}
                                    {espVisibility["all"]?.visibility && (
                                        <div style={{ margin: "0 10px" }}>
                                            <Checkbox
                                                checked={
                                                    selectedValues.length === espOptions.length ||
                                                    selectedValues.length === espOptions.length - 1
                                                }
                                                onChange={() => handleCheckboxChange("all")}
                                            />{" "}
                                            {espOptions.find((option) => option.key === "all").label}
                                        </div>
                                    )}
                                </div>
                            </Card>

                            {/* <Card
                  sx={{
                    p: 2,
                    boxShadow: '0px 2px 11px 0px #00000012',
                    borderRadius: '10px',
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    AI enabled warmup
                  </StyledTypography>
                  <Switch color="success" sx={{ ml: "auto", color: '#38C68B' }} />
                </Card> */}
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: 5,
                        }}
                    >
                        {!isButtonDisabled ? (
                            <Button
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    fontFamily: "'HK Nova', sans-serif",
                                    backgroundColor: "#e7e7e7",
                                    textTransform: "none",
                                    boxShadow: "none",
                                    mr: 2,
                                }}
                                onClick={handleReconnectClick}
                            >
                                Reconnect account
                            </Button>
                        ) : (
                            <>
                                <Tooltip title="Disabled">
                                    <span>
                                        <Button onClick={handleReconnectClick} disabled>
                                            Reconnect account
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        )}
                        <StyledButtonOutlined
                            onClick={() => setRemoveAccountDialog(true)}
                            variant="outlined"
                            sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#7D7D7D",
                                textTransform: "none",
                                boxShadow: "none",
                                fontFamily: "'HK Nova', sans-serif",
                                mr: 2,
                            }}
                        >
                            {buttonLoader && <CircularProgress size={25} />}
                            {!buttonLoader && "Remove the account"}
                        </StyledButtonOutlined>
                        <Button
                            onClick={toggleSettingDrawerClose}
                            disabled={buttonLoading}
                            variant="contained"
                            sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                fontFamily: "'HK Nova', sans-serif",
                                backgroundColor: buttonLoading ? "#e7e7e7" : "#481BEB",
                                textTransform: "none",
                                boxShadow: "none",
                            }}
                        >
                            {!buttonLoading && "Save and Close"}
                            {buttonLoading && <CircularProgress size={25} />}
                        </Button>
                    </Box>

                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={removeAccountDialog}
                    >
                        {!accounctDeleteSuccess ? (
                            <div
                                style={{
                                    maxWidth: "500px",
                                    minHeight: "270px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    px: 3,
                                    position: "relative",
                                }}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <p
                                        style={{
                                            color: "#EF6D6D",
                                            fontWeight: "700",
                                            fontSize: "20px",
                                        }}
                                    >
                                        Are you sure you want to delete account?
                                    </p>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            textAlign: "center",
                                            color: "#141B3A",
                                        }}
                                    >
                                        {settingDrawerData?.sender_email}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            textAlign: "center",
                                            width: "100%",
                                            color: "#4F4F4F",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        This will permanently remove from Campaign, Master Inbox and all the associated
                                        information linked with this account
                                    </Typography>
                                </DialogContent>
                                <DialogActions sx={{ mb: 4 }}>
                                    <Button
                                        onClick={() => setRemoveAccountDialog(false)}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#38C68B",
                                            color: "#fff",
                                            fontSize: "12px",
                                            px: 4,
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={(e) => {
                                            removeAccountHandler();
                                            setRemoveAccountDialog(false);
                                        }}
                                        variant="contained"
                                        disabled={buttonLoader}
                                        sx={{
                                            backgroundColor: "#EF6D6D",
                                            color: "#fff",
                                            fontSize: "12px",
                                            px: 4,
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </div>
                        ) : (
                            <div
                                style={{
                                    maxWidth: "500px",
                                    width: "100%",
                                    // minHeight: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px 30px",
                                }}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            textAlign: "center",
                                            mt: 1,
                                        }}
                                    >
                                        {settingDrawerData?.sender_email}
                                    </Typography>
                                    <p
                                        style={{
                                            color: "#EF6D6D",
                                            fontWeight: "700",
                                            fontFamily: "'HK Nova' sans-serif",
                                            fontSize: "20px",
                                            margin: "5px 0",
                                        }}
                                    >
                                        Account Deleted Successfully
                                    </p>
                                </DialogTitle>

                                <DialogActions sx={{}}>
                                    <Button
                                        onClick={() => setRemoveAccountDialog(false)}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#481BEB",
                                            color: "#fff",
                                            fontSize: "12px",
                                            px: 4,
                                            mb: 3,
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </div>
                        )}
                    </Dialog>

                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={updateDialog}
                    >
                        <Container maxWidth="sm">
                            <Box sx={{ marginTop: "20px" }}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="SMTP Password"
                                    onChange={(e) => setSmtpPassword(e.target.value)}
                                    required
                                    sx={{ my: 2 }}
                                />

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField
                                            required
                                            label="SMTP Host"
                                            value={smtpHost}
                                            fullWidth
                                            onChange={(e) => setSmtpHost(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField
                                            required
                                            label="SMTP Port"
                                            type="number"
                                            value={smtpPort}
                                            fullWidth
                                            disabled={smtpPort}
                                            onChange={(e) => setSmtpPort(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="warning"
                                    sx={{
                                        p: 1,
                                        mt: 2,
                                        mr: 2,
                                        margin: "10px",
                                        color: "#481BEB",
                                        borderColor: "#E7E7E7",
                                        fontSize: "12px",
                                        fontFamily: "'Inter', sans-serif",
                                        "&:hover": {
                                            borderColor: "#E7E7E7",
                                        },
                                    }}
                                    onClick={() => setUpdateDialog(false)}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#38C68B",
                                        p: 1,
                                        mt: 2,
                                        margin: "10px",
                                    }}
                                    onClick={SMTPUpdateNext}
                                >
                                    {SMTPButtonLoading && <CircularProgress size={25} />}
                                    {!SMTPButtonLoading && "Next"}
                                </Button>
                            </Box>
                        </Container>
                    </Dialog>

                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={IMAPDialog}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 4,
                                    margin: "20px",
                                }}
                            >
                                <img src={imap_smtp} alt="imap_smtp" sx={{ float: "left", width: "25px" }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                        }}
                                    >
                                        Connect to
                                    </Typography>
                                    <Typography>IMAP</Typography>
                                </Box>
                            </Box>
                            <Container maxWidth="sm">
                                <TextField
                                    fullWidth
                                    type="password"
                                    label="IMAP Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    sx={{ my: 2 }}
                                />
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField
                                            required
                                            label="IMAP Host"
                                            value={imapHost}
                                            fullWidth
                                            onChange={(e) => setImapHost(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField
                                            required
                                            label="IMAP Port"
                                            type="number"
                                            value={imapPort}
                                            fullWidth
                                            disabled={imapPort}
                                            onChange={(e) => setImapPort(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        sx={{
                                            p: 1,
                                            mt: 2,
                                            mr: 2,
                                            color: "#481BEB",
                                            borderColor: "#E7E7E7",
                                            fontSize: "12px",
                                            fontFamily: "'Inter', sans-serif",
                                            margin: "10px",
                                            "&:hover": {
                                                borderColor: "#E7E7E7",
                                            },
                                        }}
                                        onClick={handleBackToUpdateDialog}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#38C68B",
                                            p: 1,
                                            mt: 2,
                                            margin: "10px",
                                        }}
                                        onClick={UpdateIMAP}
                                    >
                                        {IMAPButtonLoading && <CircularProgress size={25} />}
                                        {!IMAPButtonLoading && "Update"}
                                    </Button>
                                </Box>
                            </Container>
                        </Box>
                    </Dialog>
                </>
            )}

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbarClose}
            >
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMsg}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default EmailAccounts;
