import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "./components/Dashboard/DashBoard";
import Teams from "./components/Dashboard/Teams";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import VerifyAccount from "./components/VerifyAccount";
import InviteWorkspace from "./components/InviteWorkspace.js";
import Campaign from "./components/Campaign/Campaign";
import Analytics from "./components/Analytics/Analytics";
import EmailAccounts from "./components/EmailAccounts/EmailAccounts";
import Integrations from "./components/Integrations/Integrations";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import CommonMailbox from "./components/CommonMailbox/CommonMailbox";
import Deliverability from "./components/Deliverability/Deliverability";
import GoogleRedirect from "./components/EmailAccounts/GoogleRedirect";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import ResetPasswordRedirect from "./components/ForgotPassword/ResetPasswordRedirect";
import Setting from "./components/Dashboard/Setting";
import WarmupTamplet from "./components/Admin pages/WarmupTamplet";
import VerifyEmail from "./components/ForgotPassword/VerifyEmail";
import Success from "./components/Subscriptions/Success";
import Failure from "./components/Subscriptions/Failure";
import TermsAndConditions from "./components/TermsConditions/terms&Cnd";
import PrivacyPolicy from "./components/TermsConditions/privacyPolicy";
import LoginData from "./components/LoginData.js";
import NotFound from "./components/NotFound/NotFound.js";


const App = () => {
    const [user, setUser] = useState("");
    const value = (user) => {
        setUser(user);
    };
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/success" element={<Success />} />
                <Route exact path="/failure" element={<Failure />} />
                <Route exact path="/login" element={<Login User={value} />} />
                <Route exact path="/admin-login" element={<LoginData User={value} />} />
                <Route exact path="/forgotPassword" element={<ForgotPassword />} />
                <Route exact path="/resetPassword" element={<ResetPassword />} />
                <Route exact path="/verifyemail" element={<VerifyEmail></VerifyEmail>} />
                <Route exact path="/reset-password/:id" element={<ResetPasswordRedirect />} />
                <Route exact path="/verify-account/:id" element={<VerifyAccount />} />
                <Route exact path="/googleredirect" element={<GoogleRedirect />} />
                <Route exact path="/accept_invite_workspace/:inviteToken" element={<InviteWorkspace />} />
                <Route path="/termsAndconditons" element={<TermsAndConditions />} />
                <Route path="/privacyAndpolicy" element={<PrivacyPolicy />} />
                <Route path="dashboard" element={<DashBoard user={user} />}>
                    <Route path="setting" element={<Setting User={value} user={user} />} />
                    <Route path="email_accounts" element={<EmailAccounts />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="warmup_tamplet" element={<WarmupTamplet />} />

                    <Route path="teams" element={<Teams User={value} user={user} />} />
                </Route>
                {/* Fallback route for undefined paths */}
                <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
            </Routes>
        </>
    );
};

export default App;
