import React, { useState } from "react";
import {
    Box,
    Typography,
    Grid,
    MenuItem,
    Button,
    CircularProgress,
    Snackbar,
    Autocomplete,
    Alert,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { StyledHeaderTypography, StyledSelectOpt, StyledTextField, StyledTypography } from "../../stylings/styles";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        border: "2px solid #7953ff",
    },
});
const Schedules = (props) => {
    const navigate = useNavigate;
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null);
    const [scheduleData, setScheduleData] = React.useState([]);
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
    const [timezone, setTimezone] = useState([]);
    const [scheduleLoading, setScheduleLoading] = React.useState(false);
    const [dataSaved, setDataSaved] = React.useState(false);
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const [selectedTimezone, setSelectedTimezone] = useState("");

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    React.useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/get_schedule`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                campaign: {
                    campaign_id: props.id,
                },
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    console.log("some error");
                }
            })
            .then((data) => {
                let zone = data.data.data[0].timezone_idtimezone;
                setTimezone(data.timezone);
                setScheduleData(data["data"]);
                setSelectedTimezone(data.timezone.find((option) => option.idtimezone === zone));
            })
            .catch((error) => console.log(error));
    }, [dataSaved, navigate, props]);

    const handleDaysClicked = (day) => {
        let tempData = scheduleData;
        tempData["data"][0][day] = -1 * tempData["data"][0][day] + 1;
        setScheduleData(tempData);
        setIsSomethingChanged(!isSomethingChanged);
    };

    const handleTimezoneChange = (newValue) => {
        if (newValue !== null) {
            let tempData = scheduleData;
            tempData["data"][0]["timezone_idtimezone"] = newValue.idtimezone;
            setScheduleData(tempData);
            setIsSomethingChanged(!isSomethingChanged);
            setSelectedTimezone(newValue);
        }
    };

    const from_time = [];
    const to_time = [];
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            from_time.push(`0${i}:00`);
            to_time.push(`0${i}:00`);
            from_time.push(`0${i}:15`);
            to_time.push(`0${i}:15`);
            from_time.push(`0${i}:30`);
            to_time.push(`0${i}:30`);
            from_time.push(`0${i}:45`);
            to_time.push(`0${i}:45`);
        } else {
            from_time.push(`${i}:00`);
            to_time.push(`${i}:00`);
            from_time.push(`${i}:15`);
            to_time.push(`${i}:15`);
            from_time.push(`${i}:30`);
            to_time.push(`${i}:30`);
            from_time.push(`${i}:45`);
            to_time.push(`${i}:45`);
        }
    }
    const handleStartTimeChange = (e) => {
        let tempData = scheduleData;
        if (e.target.value > tempData["data"][0]["to_time"]) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Start time must be less than Endtime");
        } else {
            tempData["data"][0]["from_time"] = e.target.value;
            setScheduleData(tempData);
            setIsSomethingChanged(!isSomethingChanged);
        }
    };
    const handleEndTimeChange = (e) => {
        let tempData = scheduleData;
        if (tempData["data"][0]["from_time"] > e.target.value) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Start time must be less than Endtime");
        } else {
            tempData["data"][0]["to_time"] = e.target.value;
            setScheduleData(tempData);
            setIsSomethingChanged(!isSomethingChanged);
        }
    };
    const schedulesSaveHandler = () => {
        setScheduleLoading(true);
        const d = scheduleData["data"][0];
        if (
            d.monday === 0 &&
            d.tuesday === 0 &&
            d.wednesday === 0 &&
            d.thursday === 0 &&
            d.friday === 0 &&
            d.saturday === 0 &&
            d.sunday === 0
        ) {
            setScheduleLoading(false);
            setErrorSnackbarOpen(true);
            setErrorMessage("Select Atleast One Day Of Week");
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/schedule_update`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                data: scheduleData,
                time_zone: selectedTimezone,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setDataSaved(!dataSaved);
                    setScheduleLoading(false);
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                    setScheduleLoading(false);
                    return response.json();
                } else {
                    console.log("some error");
                    setScheduleLoading(false);
                    setErrorSnackbarOpen(true);
                    props.saveErrorHandler();
                    return response.json();
                }
            })
            .then((data) => {
                setErrorMessage(data.msg);
                setSuccessMessage(data.msg);
                console.log(data);
            })
            .catch((error) => console.log(error));
    };

    return (
        <>
            {scheduleData.length === 0 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
            {scheduleData.length !== 0 && (
                <>
                    <StyledHeaderTypography sx={{ fontSize: "24px", fontWeight: "700", mb: 1 }}>
                        Timing is the key!
                    </StyledHeaderTypography>
                    <StyledTypography sx={{ fontSize: "16px", fontWeight: "700", pb: 2 }}>
                        Based on this timezone
                    </StyledTypography>
                    {/* <StyledSelectOpt
                        fullWidth
                        select
                        onChange={handleTimezoneChange}
                        //value={scheduleData['data'][0]["timezone_idtimezone"]}
                        value={selectedTimezone}
                    >
                        {
                           timezone.map((i,j)=>{
                           return (<MenuItem id={i.idtimezone} value={i.idtimezone}>{i.name}</MenuItem>);
                        }) 
                        }
                        
                    </StyledSelectOpt> */}
                    <Autocomplete
                        fullWidth
                        options={timezone}
                        getOptionLabel={(option) => option.name}
                        value={selectedTimezone}
                        onChange={(e, newValue) => {
                            handleTimezoneChange(newValue);
                        }}
                        renderInput={(params) => <StyledTextField {...params} label="Timezone" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.idtimezone} value={option.idtimezone}>
                                {option.name}
                            </li>
                        )}
                    />
                    <StyledTypography sx={{ fontSize: "16px", fontWeight: "700", mb: 2, mt: 3 }}>
                        Send these days
                    </StyledTypography>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        {days.map((day) => (
                            <ToggleButton
                                value={day}
                                selected={scheduleData["data"][0][day] === 1}
                                onChange={() => handleDaysClicked(day)}
                                color="primary"
                                sx={{
                                    width: "100px",
                                    height: "60px",
                                    fontWeight: scheduleData["data"][0][day] === 1 ? "600" : "400",
                                }}
                            >
                                {day.slice(0, 3)}
                                {scheduleData["data"][0][day] === 1 && (
                                    <span style={{ position: "absolute", top: 0, right: 0 }}>
                                        <CheckCircleOutlinedIcon /> {/* Replace this with the actual checked icon */}
                                    </span>
                                )}
                            </ToggleButton>
                        ))}
                    </Box>
                    <StyledTypography sx={{ fontSize: "16px", fontWeight: "700", pb: 2, pt: 3 }}>
                        And finally at what time?
                    </StyledTypography>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                        >
                            <Typography sx={{}}>Start time</Typography>
                            <StyledSelectOpt
                                sx={{}}
                                style={{ width: "300px" }}
                                onChange={handleStartTimeChange}
                                value={scheduleData["data"][0]["from_time"]}
                            >
                                {from_time.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </StyledSelectOpt>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                        >
                            <Typography sx={{}}>End time</Typography>
                            <StyledSelectOpt
                                sx={{}}
                                style={{ width: "300px" }}
                                onChange={handleEndTimeChange}
                                value={scheduleData["data"][0]["to_time"]}
                            >
                                {to_time.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </StyledSelectOpt>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Button onClick={schedulesSaveHandler} variant="contained" disabled={scheduleLoading}>
                            {scheduleLoading && <CircularProgress size={25} />}
                            {!scheduleLoading && "Save"}
                        </Button>
                    </Box>
                </>
            )}
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbarClose}
            >
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Schedules;
