import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";
import appLogo from "../../img/icons/XemailWarmupBremove.png";

const NotFound = () => {
    return (
        <div className="notfound-container">
            <div className="logo-container">
                <img src={appLogo} alt="App Logo" className="app-logo" />
            </div>
            <h1 className="notfound-title">404</h1>
            <h2 className="notfound-subtitle">Oops! Page Not Found</h2>
            <p className="notfound-text">
                We're sorry, but the page you're looking for doesn't exist. It might have been moved or deleted.
            </p>
            <Link to="/" className="notfound-button">
                Go Back to Home
            </Link>
        </div>
    );
};

export default NotFound;
