import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    Stack,
    Snackbar,
    Alert,
    Table,
    TableBody,
    IconButton,
    TableHead,
    TableRow,
    Backdrop,
    MenuItem,
    OutlinedInput,
} from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cookie from "cookiejs";
import jwt from "jwt-decode";
import { useEffect } from "react";
import TeamsFunctions from "./TeamsFunctions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
    StyledHeaderTypography,
    StyledSelectOpt,
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
    StyledTextField,
    colorTheme,
} from "../../stylings/styles";
import exploreIcon from "../../img/icons/Frame.png";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#FAFAFA",
//     fontSize: "12px",
//     fontWeight: "700"
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   // hide last border

//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Setting(props) {
    const navigate = useNavigate();
    const [workspaceData, setWorkspaceData] = React.useState([]);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [searchValue, setSearchValue] = React.useState("");
    const [deleteWorkspaceDialougeOpen, setdDleteWorkspaceDialougeOpen] = React.useState(false);
    const [deleteId, setdDeleteId] = React.useState("");
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
    const [renameWorkspaceDialougeOpen, setRenameWorkspaceDialougeOpen] = React.useState(false);
    const [rename, setRename] = React.useState({ name: "", id: "" });
    const [workspaceDialog, setWorkspaceDialog] = React.useState(false);
    const [workspaceName, setWorkspaceName] = React.useState("");
    const [selectedWorkspace, setSelectedWorkspace] = React.useState(null);
    const [workspaceId, setWorkspaceId] = React.useState(null);
    const [workspaceRole, setWorkspaceRole] = React.useState(null);
    const [emailDialog, setEmailDialog] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState("");
    const [userRole, setUserRole] = React.useState("");
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [isAdmin, setIsAdmin] = useState("");
    const [inviteLoading, setInviteLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            const user = jwt(isLogin);
            setIsAdmin(user.admin);
            setFName(user.firstname);
            setLName(user.lastname);
        }
    }, [navigate]);

    function validateEmail(data) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(data);
    }
    useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/get_workspace`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    console.log("some error");
                }
            })
            .then((data) => {
                setWorkspaceData(data.data);
            })
            .catch((error) => console.log(error));
    }, [isSomethingChanged, navigate]);

    const validateWorkspaceName = () => {
        return workspaceName.replace(/^\s+|\s+$/g, "").length === 0 ? true : false;
    };
    const deleteWorkspaceClicked = (id) => {
        setdDleteWorkspaceDialougeOpen(true);
        setdDeleteId(id);
    };
    const deleteWorkspaceHandle = (id) => {
        console.log(id);
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/delete_workspace?App_type=Xwarmup`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                deleteWorkspaceId: id,
            }),
        })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setIsSomethingChanged(!isSomethingChanged);
                    return response.json();
                } else if (response.status === 202) {
                    seterrorMsg(response.msg);
                    setErrorSnackbarOpen(true);
                    console.log("token expired");
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    seterrorMsg(response.msg);
                    setErrorSnackbarOpen(true);
                    console.log("some error");
                }
            })
            .then((data) => {
                setsuccessMsg(data.msg);
                setSuccessSnackbarOpen(true);
            })
            .catch((error) => console.log(error));
        setdDleteWorkspaceDialougeOpen(false);
    };
    const renameWorkspaceHandle = () => {
        setRenameWorkspaceDialougeOpen(false);
        if (rename) {
            fetch(`${REACT_APP_SERVER_URL}/update_workspace`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    updateName: rename.name,
                    idWorkspace: rename.id,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setsuccessMsg("Change Workspace name Successfully");
                        setIsSomethingChanged(!isSomethingChanged);
                        setSuccessSnackbarOpen(true);
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        seterrorMsg("Something went Wrong");
                        setErrorSnackbarOpen(true);
                    }
                })
                .catch((error) => {
                    seterrorMsg(error);
                    setIsSomethingChanged(!isSomethingChanged);
                    setErrorSnackbarOpen(true);
                });
        }
    };
    const handleWorkspaceClick = (id, status, name) => {
        setSelectedWorkspace(name);
        setWorkspaceId(id);
        console.log(workspaceId);
        setWorkspaceRole(status);
    };
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbar = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };
    const handleWorkspaceDialogClose = () => {
        setWorkspaceDialog(false);
    };
    const renameDialougeOpen = (name = "", id = "") => {
        setRename({ name: name, id: id });
        setRenameWorkspaceDialougeOpen(true);
    };
    const newWorkspaceSubmitHandler = (e) => {
        setLoading(true);

        if (workspaceName === "" || validateWorkspaceName()) {
            seterrorMsg("Please Enter Correct name for the new workspace");
            setErrorSnackbarOpen(true);
        } else {
            fetch(`${REACT_APP_SERVER_URL}/add_workspace?App_type=Xwarmup`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({ name: workspaceName }),
            })
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        setSuccessSnackbarOpen(true);
                        setsuccessMsg("Workspace Added successfully");
                        setIsSomethingChanged(!isSomethingChanged);
                        handleWorkspaceDialogClose();
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        console.log("some error");
                    }
                })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => console.log(error));
        }
    };
    const saveErrorHandler = () => {
        seterrorMsg("Something Went Wrong");
        setErrorSnackbarOpen(true);
    };
    const handleInviteDialogClose = () => {
        setEmailDialog(false);
    };
    const newEmailAddSubmitHandler = (e) => {
        setInviteLoading(true);
        if (userEmail === "" || !validateEmail(userEmail)) {
            seterrorMsg("Please Enter Correct name for the new workspace");
            setErrorSnackbarOpen(true);
            setInviteLoading(false);
        }
        fetch(`${REACT_APP_SERVER_URL}/invite_workspace`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({ inviteUserEmail: userEmail, inviteRole: userRole, inviteWorkspaceId: workspaceId }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsSomethingChanged(!isSomethingChanged);
                    setUserEmail("");
                    setUserRole("");
                    handleInviteDialogClose();
                    setInviteLoading(false);
                    return response.json();
                } else if (response.status === 202) {
                    console.log("token expired");
                    setInviteLoading(false);
                    navigate("/login", { state: { sessionExpired: true } });
                } else {
                    console.log("some error");
                    setInviteLoading(false);
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setsuccessMsg(data.msg);
                    setSuccessSnackbarOpen(true);
                    return;
                }
                seterrorMsg(data.msg);
                setErrorSnackbarOpen(true);
            })
            .catch((error) => console.log(error));
    };
    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        })
    );
    return (
        <ThemeProvider theme={colorTheme}>
            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" },
                }}
                open={deleteWorkspaceDialougeOpen}
            >
                <div
                    style={{
                        width: "500px",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                            Are you sure you want to delete account?
                        </p>
                    </DialogTitle>
                    <DialogActions sx={{ mb: 4, display: "flex", justifyContent: "center", gap: "20px" }}>
                        {loading ? (
                            <CircularProgress></CircularProgress>
                        ) : (
                            <Button
                                onClick={() => deleteWorkspaceHandle(deleteId)}
                                variant="contained"
                                color="success"
                                sx={{ color: "#fff" }}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                setdDleteWorkspaceDialougeOpen(false);
                                setdDeleteId("");
                            }}
                            variant="contained"
                            color="error"
                            sx={{ color: "#fff" }}
                            disabled={false}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" },
                }}
                open={renameWorkspaceDialougeOpen}
            >
                <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
                    <StyledTextField
                        sx={{ width: "300px", mt: 1 }}
                        value={rename.name}
                        onChange={(e) => setRename({ ...rename, name: e.target.value })}
                        variant="outlined"
                    />
                </DialogTitle>
                <DialogActions sx={{ mb: 4, display: "flex", justifyContent: "center", gap: "20px" }}>
                    <Button
                        onClick={() => renameWorkspaceHandle()}
                        variant="contained"
                        color="success"
                        sx={{ color: "#fff" }}
                    >
                        Rename
                    </Button>
                    <Button
                        onClick={() => {
                            setRenameWorkspaceDialougeOpen(false);
                            setRename({ name: "", id: "" });
                        }}
                        variant="contained"
                        color="error"
                        sx={{ color: "#fff" }}
                        disabled={false}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {workspaceId !== null && (
                <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center" mb="3">
                    <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <IconButton
                            sx={{
                                float: "left",
                                width: "48px",
                                height: "48px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "11px",
                                boxShadow: "0 2px 11px 0px #0000001a",
                            }}
                            variant="contained"
                            disabled={workspaceId === null}
                            onClick={() => handleWorkspaceClick(null, null, null)}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: "18px", fontWeight: 700, float: "left" }}>
                            {selectedWorkspace}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => setEmailDialog(true)}
                        sx={{
                            textTransform: "none",
                            fontSize: "14px",
                            fontWeight: "700",
                            backgroundColor: "#481BEB",
                            width: "150px",
                        }}
                    >
                        + Invite User
                    </Button>
                </Stack>
            )}
            {workspaceId === null && (
                <Box>
                    {workspaceData.length === 0 && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {workspaceData.length !== 0 && (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <StyledHeaderTypography
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "20px",
                                                fontSize: "24px",
                                            }}
                                        >
                                            {isAdmin ? <h>Admin</h> : fname}'s Workspace{" "}
                                        </StyledHeaderTypography>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: "#4F4F4F",
                                                fontFamily: "'Inter', sans-serif",
                                            }}
                                        >
                                            List Of Your Workspace's
                                        </Typography>
                                    </Box>
                                    {/* <Paper
                    component="form"
                    sx={{ mr: 2, mb: 2, display: 'flex', alignItems: 'center', width: '30%', height: "2.2rem", boxShadow: "none", border: "1px solid #aaa", float: 'left', pl: 1 }}
                  >
                    <SearchIcon sx={{ color: "#aaa" }} />

                    <InputBase
                      sx={{ flex: 1 }}
                      value={searchValue}
                      placeholder="Search; keyword, title of Workspace"
                      onChange={(e) => setSearchValue(e.target.value)}
                      inputProps={{ 'aria-label': 'search for projects' }}
                    />
                  </Paper> */}
                                    <Button
                                        variant="contained"
                                        onClick={() => setWorkspaceDialog(true)}
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                            backgroundColor: "#481BEB",
                                            width: "150px",
                                        }}
                                    >
                                        + Add new
                                    </Button>
                                </Stack>
                                {/* <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "#EFA10D", fontFamily: "'HK Nova', sans-serif", mt: 3 }}>1 of 5 Seats available</Typography> */}
                            </Box>
                            <Box>
                                <StyledTableContainer sx={{ p: 3 }}>
                                    <Table
                                        sx={{
                                            width: "100%",
                                            minWidth: 650,
                                            borderCollapse: "separate",
                                            borderSpacing: "0 10px",
                                        }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell sx={{ color: "#BEBEBE !important" }} align="left">
                                                    NAME
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ color: "#BEBEBE !important" }} align="right">
                                                    STATUS
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ color: "#BEBEBE !important" }} align="right">
                                                    EDIT
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ color: "#BEBEBE !important" }} align="right">
                                                    DELETE
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {workspaceData &&
                                                workspaceData
                                                    .filter((x) =>
                                                        x["name"].toLowerCase().includes(searchValue.toLowerCase())
                                                    )
                                                    .map((data, i) => (
                                                        <StyledTableRow
                                                            key={i}
                                                            hover
                                                            sx={{
                                                                cursor: "pointer",
                                                                width: "8rem",
                                                                height: "4rem",
                                                                bgcolor: (theme) =>
                                                                    theme.palette.mode === "dark" ? "#101010" : "#fff",
                                                                color: (theme) =>
                                                                    theme.palette.mode === "dark"
                                                                        ? "grey.300"
                                                                        : "grey.800",
                                                                p: 1,
                                                                m: 1,
                                                                borderRadius: 2,
                                                                textAlign: "center",
                                                                fontSize: "0.875rem",
                                                            }}
                                                        >
                                                            <StyledTableCell
                                                                onClick={() =>
                                                                    handleWorkspaceClick(
                                                                        data["idworkspace"],
                                                                        data["status"],
                                                                        data["name"]
                                                                    )
                                                                }
                                                                align="left"
                                                            >
                                                                <Stack direction="column">
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: "700",
                                                                            fontFamily: "'HK Nova', sans-serif",
                                                                        }}
                                                                    >
                                                                        {data["name"]}
                                                                    </Typography>
                                                                    {/* <Typography sx={{ fontSize: "12px", fontWeight: '400', fontFamily: "'Inter', sans-serif", color: '#7D7D7D' }}>{data['email']}</Typography> */}
                                                                </Stack>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                onClick={() =>
                                                                    handleWorkspaceClick(
                                                                        data["idworkspace"],
                                                                        data["status"],
                                                                        data["name"]
                                                                    )
                                                                }
                                                                align="right"
                                                            >
                                                                {data["role"]}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                onClick={() =>
                                                                    handleWorkspaceClick(
                                                                        data["idworkspace"],
                                                                        data["status"],
                                                                        data["name"]
                                                                    )
                                                                }
                                                                align="right"
                                                            >
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        renameDialougeOpen(
                                                                            data["name"],
                                                                            data["idworkspace"]
                                                                        );
                                                                    }}
                                                                >
                                                                    <Tooltip title="Edit Button">
                                                                        <EditIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="right"
                                                                sx={{ fontSize: "14px", fontWeight: "700" }}
                                                            >
                                                                <Button
                                                                    sx={{ color: "#989898" }}
                                                                    onClick={() =>
                                                                        deleteWorkspaceClicked(data["idworkspace"])
                                                                    }
                                                                >
                                                                    <LightTooltip title="Delete Button">
                                                                        <Delete />
                                                                    </LightTooltip>
                                                                </Button>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                        </TableBody>
                                    </Table>
                                </StyledTableContainer>
                            </Box>
                        </>
                    )}
                </Box>
            )}
            {workspaceId !== null && (
                <TeamsFunctions
                    name={selectedWorkspace}
                    role={workspaceRole}
                    id={workspaceId}
                    handleBackButtonClick={handleWorkspaceClick}
                    saveErrorHandler={saveErrorHandler}
                />
            )}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={workspaceDialog}>
                <Stack gap={1} justifyContent="center" alignItems="center">
                    <Box
                        sx={{
                            color: "black",
                            maxWidth: "620px",
                            p: 3,
                            height: "auto",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "510px",
                                height: "auto",
                                borderRadius: "30px",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                gap: "15px",
                                p: 4,
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", my: 2 }}>
                                <StyledHeaderTypography
                                    sx={{ display: "flex", alignItems: "center", gap: "20px", fontSize: "24px" }}
                                >
                                    {isAdmin ? <h>Admin</h> : fname}'s Workspace <img src={exploreIcon} alt="icon" />{" "}
                                </StyledHeaderTypography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        color: "#4F4F4F",
                                        fontFamily: "'Inter', sans-serif",
                                    }}
                                >
                                    You can add up to 5 team members on your current plan
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "#EFA10D",
                                        fontFamily: "'HK Nova', sans-serif",
                                    }}
                                >
                                    1 of 5 Seats available
                                </Typography>
                            </Box>

                            {/* <Stack direction='row'>
                <StyledTextField
                  label="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  fullWidth
                  sx={{ backgroundColor: "rgb(247, 245, 245)", border: "none", outline: "none", borderRadius: "10px" }}
                />
                <StyledSelectOpt
                  fullWidth
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  required
                  value={userRole}
                  onChange={(e) => {
                    let val = e.target.value;
                    setUserRole(val);
                  }}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                >
                  <MenuItem
                    value="admin"
                  >
                    Admin
                  </MenuItem>
                  <MenuItem
                    value="editor"
                  >
                    Editor
                  </MenuItem>
                  <MenuItem
                    value="view"
                  >
                    View
                  </MenuItem>
                </StyledSelectOpt>
              </Stack> */}

                            <StyledTextField
                                label="name"
                                onChange={(e) => setWorkspaceName(e.target.value)}
                                fullWidth
                                sx={{ mb: 0 }}
                            />

                            {/* <Typography 
                  sx={{
                    color: "#7860DA", 
                    cursor: 'pointer',
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "'HK Nova', sans-serif",
                    "&:hover": {
                      backgroundColor: 'white',
                      color: "#481BEB",
                    }
                  }}
                >
                  + Add another member
                </Typography> */}

                            <Stack direction="row" spacing={2} alignItems="center">
                                {loading ? (
                                    <CircularProgress></CircularProgress>
                                ) : (
                                    <Button
                                        // onClick={newEmailAddSubmitHandler}

                                        onClick={newWorkspaceSubmitHandler}
                                        sx={{
                                            backgroundColor: "#481BEB",
                                            color: "white",
                                            border: "1px solid",
                                            fontSize: "12px",
                                            py: 1,
                                            px: 3,
                                            fontFamily: "'HK Nova', sans-serif",
                                            "&:hover": {
                                                backgroundColor: "white",
                                                color: "#481BEB",
                                                border: "1px solid",
                                            },
                                        }}
                                    >
                                        + Add another member
                                    </Button>
                                )}

                                {/* <Button
                  onClick={newWorkspaceSubmitHandler}
                  sx={{
                    backgroundColor: "transparent", color: "#7D7D7D",
                    border: "none", fontSize: '12px', py: 1, px: 3, fontFamily: "'HK Nova', sans-serif",
                    "&:hover": {
                      backgroundColor: 'white',
                      color: "#481BEB",
                      border: "1px solid"
                    }
                  }}
                >
                  Cancel
                </Button> */}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                            }}
                        >
                            <Button
                                onClick={handleWorkspaceDialogClose}
                                sx={{ borderRadius: "50%", backgroundColor: "none" }}
                            >
                                X
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Backdrop>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={emailDialog}>
                <Stack gap={1} justifyContent="center" alignItems="center">
                    <Box sx={{ color: "black", p: 3, display: "flex", justifyContent: "space-around" }}>
                        <Box
                            sx={{
                                width: "510px",
                                borderRadius: "20px",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                p: 4,
                            }}
                        >
                            <StyledHeaderTypography sx={{ fontSize: "25px", mb: 1 }}>
                                Invite User
                            </StyledHeaderTypography>

                            <StyledTextField
                                label="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                fullWidth
                                sx={{
                                    backgroundColor: "rgb(247, 245, 245)",
                                    border: "none",
                                    outline: "none",
                                    borderRadius: "10px",
                                }}
                            />
                            <StyledSelectOpt
                                fullWidth
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                required
                                value={userRole}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    setUserRole(val);
                                }}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="editor">Editor</MenuItem>
                                <MenuItem value="view">View</MenuItem>
                            </StyledSelectOpt>
                            <Button
                                onClick={newEmailAddSubmitHandler}
                                sx={{
                                    backgroundColor: "rgb(57, 29, 240)",
                                    color: "white",
                                    mt: 2,
                                    width: "10rem",
                                    border: "1px solid",
                                    "&:hover": {
                                        backgroundColor: "white",
                                        color: "blue",
                                        border: "1px solid",
                                    },
                                }}
                            >
                                {inviteLoading && <CircularProgress size={25} sx={{ color: "white" }} />}
                                {!inviteLoading && "+ Send Invite"}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                            }}
                        >
                            <Button
                                onClick={handleInviteDialogClose}
                                sx={{ borderRadius: "50%", backgroundColor: "none" }}
                            >
                                X
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Backdrop>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbar}
            >
                <Alert onClose={handleSuccessSnackbar} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMsg}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

export default Setting;
