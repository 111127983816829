import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    Stack,
    CssBaseline,
    Box,
    Container,
    Typography,
    Button,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import HomeButton from "../../img/icons/HomeButton.png";
import rectangle from "../../img/svg/rectangle.png";
import ring from "../../img/svg/ring.png";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["HK Nova", "Inter"].join(","),
    },
});
const ForgotPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState(state === null ? "" : state.msg);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [forgetLoading, setForgetLoading] = React.useState(false);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        setForgetLoading(true);
        console.log(
            new URLSearchParams({
                email: email,
            }).toString()
        );

        if (!email) {
            setForgetLoading(false);
            setErrorMessage("Input email is required");
            setErrorSnackbarOpen(true);
        } else {
            fetch(`${REACT_APP_SERVER_URL}/forget-password?App_type=Xwarmup`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        setForgetLoading(false);
                        setSuccessSnackbarOpen(true);
                        setTimeout(() => {
                            navigate("/login");
                        }, 5000);
                        return response.json();
                    } else {
                        setForgetLoading(false);
                        setErrorSnackbarOpen(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    setErrorMessage(data.msg);
                    setSuccessMessage(data.msg);
                });
        }
    };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Link exact to="/">
                <img src={HomeButton} alt="brand-logo" style={{ cursor: "pointer" }} />
            </Link>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={xCampaignLogo} alt="brand-logo" />
            </Box>
            <Box
                maxWidth="sm"
                sx={{
                    margin: "0 auto",
                    mt: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <Container
                    maxWidth="xs"
                    sx={{
                        zIndex: 10,
                        backgroundColor: "#fff",
                        p: 5,
                        px: 4,
                        mx: 2,
                        boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)",
                        borderRadius: "40px",
                    }}
                >
                    <Typography
                        sx={{ fontSize: "32px", fontWeight: "bold", fontFamily: "'HK Nova', sans-serif", mb: 4 }}
                    >
                        Forgot Password
                    </Typography>
                    <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif" }}>
                        Email
                    </Typography>
                    <TextField
                        required
                        placeholder="Enter Last Name"
                        type="email"
                        value={email}
                        name="email"
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        inputProps={{
                            style: { color: "#9491AD", fontSize: "14px" },
                        }}
                        sx={{
                            fontFamily: "'Inter', sans-serif",
                            fontWeight: "400",
                            fontSize: "14px",
                            backgroundColor: "#f7f7f9",
                            borderRadius: "8px",
                            color: "#9491AD",
                            mb: 3,
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                    />
                    {/* <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                            >
                                Send Reset Link
                            </Button> */}
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={submitHandler}
                            sx={{
                                my: 1,
                                py: 2,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "500",
                                backgroundColor: "#3D17C6",
                            }}
                            disabled={forgetLoading}
                        >
                            {forgetLoading && <CircularProgress size={25} />}
                            {!forgetLoading && "Send Reset Link"}
                        </Button>
                    }
                    <Stack direction="row" sx={{ mt: 2, fontFamily: "'HK Nova', sans-serif" }}>
                        <Typography sx={{ mr: 0.5, fontSize: "14px", color: "#4F4F4F" }}>Back to</Typography>
                        <Link
                            exact
                            to="/login"
                            style={{ color: "#3D17C6", fontSize: "14px", textDecoration: "none", fontWeight: "700" }}
                        >
                            Login
                        </Link>
                    </Stack>
                </Container>
                <svg
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "absolute", right: "calc(100% - 15%)", top: "0", zIndex: 1 }}
                >
                    <circle cx="23.5" cy="23.5" r="23.5" fill="#FFE163" />
                </svg>
                <img src={rectangle} alt="svg" style={{ position: "absolute", bottom: "-50px", left: "0" }} />
            </Box>
            <img src={ring} alt="svg" style={{ width: "120px", position: "absolute", bottom: "", right: "10%" }} />

            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbarClose}
            >
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default ForgotPassword;
