import React, { useState } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Toolbar,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    Drawer as MuiDrawer,
    Container,
    Stack,
    MenuItem,
    Menu,
    FormControl,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

import { NavLink, Link, useNavigate, Outlet } from "react-router-dom";
import analyticsLogo from "../../img/icons/analyticsLogo.png";
import commonMailLogo from "../../img/icons/commonMailLogo.png";
import emailAcc from "../../img/icons/emailAcc.png";
import subscriptionLogo from "../../img/icons/subscriptionLogo.png";
import campaignLogo from "../../img/icons/campaignLogo.png";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import userProf from "../../img/images/Ellipse 2.png";
import { StyledSelectOpt, colorTheme } from "../../stylings/styles";
import XemailWarmUp from "../../img/icons/XemailWarmupBremove.png";

const drawerWidth = 244;

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        backgroundColor: "#E7E7E7",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const DashBoard = (props) => {
    const navigate = useNavigate();
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [isadmin, setAdmin] = React.useState("");
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(null);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedEmail, setSelectedEmail] = React.useState({});
    const [allWorkshops, setAllWorkshops] = React.useState([]);
    const [workspaceData, setWorkspaceData] = React.useState(false);
    //uncomment for server development
    //comment for local development

    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            const user = jwt(isLogin);
            console.log(user);
            setAdmin(user.admin);
            setFName(user.firstname);
            setLName(user.lastname);
        }
    }, [navigate]);

    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            setIsLoading(false);
            fetch(`${REACT_APP_SERVER_URL}/get_workspace`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 202) {
                        console.log("token expired");
                        navigate("/login", { state: { sessionExpired: true } });
                    } else {
                        console.log("some error");
                    }
                })
                .then((data) => {
                    setAllWorkshops(data.data);
                    let index = data.data.findIndex((x) => x.idworkspace === data.defaultWorkspaceId);
                    setSelectedEmail(JSON.stringify(data.data[index]));
                    setIsLoading(true);
                })
                .catch((error) => {
                    setIsLoading(true);
                    console.log(error);
                });
        }
    }, [workspaceData]);

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    };

    const handleWorkSpaceChange = async (e) => {
        setIsLoading(false);

        try {
            const key = JSON.parse(e.target.value);
            const response = await fetch(`${REACT_APP_SERVER_URL}/set_workspace`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    newWorkspaceId: key.idworkspace,
                }),
            });

            if (response.status === 200) {
                setWorkspaceData(!workspaceData);
                return response.json();
            } else if (response.status === 202) {
                console.log("token expired");
                navigate("/login", { state: { sessionExpired: true } });
            } else {
                console.log("some error");
            }
        } catch (error) {
            setIsLoading(true);
            console.log(error);
        }
    };

    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                console.log("User Logged out");
                navigate("/login");
                return response.json();
            } else {
                console.log("Some error");
            }
        });
    };
    return (
        <ThemeProvider theme={colorTheme}>
            {!isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 5,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {isLoading && (
                <>
                    <Box sx={{ display: "flex" }}>
                        <CssBaseline />
                        {/* <AppBar elevation={0} position="absolute" open={drawerOpen} sx={{ backgroundColor: "#fff" }}>
                            <Toolbar
                                sx={{
                                    pr: '24px',
                                }}
                            >
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer}
                                    sx={{
                                        marginRight: '36px',
                                        ...(drawerOpen && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon color='action' />
                                </IconButton>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="#000"
                                    noWrap
                                    sx={{ flexGrow: 1 }}
                                >
                                    Email Accounts
                                </Typography>
                                <IconButton sx={{ mx: 2, border: "1px solid" }}>
                                    <QuestionMarkOutlinedIcon />
                                </IconButton>
                                <IconButton sx={{ mx: 2, border: "1px solid" }}>
                                    <Badge badgeContent={1} color="error">
                                        <NotificationsNoneOutlinedIcon />
                                    </Badge>
                                </IconButton>
                                <Avatar onClick={handleProfileMenuOpen} sx={{ mx: 2, cursor: "pointer" }}>
                                    L
                                </Avatar>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={profileMenuOpen}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(profileMenuOpen)}
                                    onClose={handleProfileMenuClose}
                                >
                                    <MenuItem onClick={handleLogoutAPI}>Logout</MenuItem>
                                </Menu>
                            </Toolbar>
                        </AppBar> */}
                        <Drawer variant="permanent" open={drawerOpen} sx={{ backgroundColor: "#F7F7F9" }}>
                            <Toolbar
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mt: 1,
                                    mb: 2,
                                }}
                            >
                                <Link>
                                    <div
                                        style={{
                                            background: "#E7E7E7",
                                            marginBottom: "-113px",
                                            marginTop: "-77px",
                                            // mb:'-113px', mt:'-77px'
                                        }}
                                    >
                                        <img src={XemailWarmUp} alt="x_logo" style={{ width: "100%" }} />
                                    </div>
                                </Link>
                            </Toolbar>
                            <List sx={{ pl: 2, fontFamily: "'Inter', sans-serif" }}>
                                <NavLink exact to="email_accounts" style={{ textDecoration: "none", color: "#4F4F4F" }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor:
                                                window.location.pathname.split("/")[2] === "email_accounts"
                                                    ? "#fff"
                                                    : "none",
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight:
                                                window.location.pathname.split("/")[2] === "email_accounts"
                                                    ? "4px solid #7953FF"
                                                    : "none",
                                            width: "100%",
                                            mb: 2,
                                        }}
                                    >
                                        <img src={emailAcc} alt="" style={{ marginRight: 10 }} />
                                        Email Accounts
                                    </ListItem>
                                </NavLink>

                                {/* <NavLink exact to='campaign' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor: window.location.pathname.split('/')[2] === 'campaign' ? '#fff' : 'none',
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight: window.location.pathname.split('/')[2] === 'campaign' ? "4px solid #7953FF" : 'none',
                                            width: "100%",
                                            mb: 2

                                        }}
                                    >
                                        <img src={campaignLogo} alt="" style={{ marginRight: 10 }} />Campaign
                                    </ListItem>
                                </NavLink>
                                <NavLink exact to='analytics' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor: window.location.pathname.split('/')[2] === 'analytics' ? '#fff' : 'none',
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight: window.location.pathname.split('/')[2] === 'analytics' ? "4px solid #7953FF" : 'none',
                                            width: "100%",
                                            mb: 2
                                        }}
                                    >
                                        <img src={analyticsLogo} alt="" style={{ marginRight: 10 }} />Analytics
                                    </ListItem>
                                </NavLink>

                                <NavLink exact to='common_mailbox' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor: window.location.pathname.split('/')[2] === 'common_mailbox' ? '#F7F7F9' : 'none',
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight: window.location.pathname.split('/')[2] === 'common_mailbox' ? "4px solid #7953FF" : 'none',
                                            width: "100%",
                                            mb: 2
                                        }}
                                    >
                                        <img src={commonMailLogo} alt="" style={{ marginRight: 10 }} />Common Mailbox
                                    </ListItem>
                                </NavLink> */}

                                {/* <NavLink exact to='deliverability' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor: window.location.pathname.split('/')[2] === 'deliverability' ? '#fff' : 'none',
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight: window.location.pathname.split('/')[2] === 'deliverability' ? "4px solid #7953FF" : 'none',
                                            width: "100%",
                                            mb: 2
                                        }}
                                    >
                                        <img src={deliverabiltyLogo} alt="" style={{ marginRight: 10 }} />Deliverability
                                    </ListItem>
                                </NavLink>
                                <NavLink exact to='integrations' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                    <ListItem
                                        sx={{
                                            backgroundColor: window.location.pathname.split('/')[2] === 'integrations' ? '#fff' : 'none',
                                            borderRadius: "50px 0px 0px 50px",
                                            borderRight: window.location.pathname.split('/')[2] === 'integrations' ? "4px solid #7953FF" : 'none',
                                            width: "100%",
                                            mb: 2
                                        }}
                                    >
                                        <img src={integrationsLogo} alt="" style={{ marginRight: 10 }} />Integrations
                                    </ListItem>

                                </NavLink> */}

                                {
                                    <NavLink
                                        exact
                                        to="warmup_tamplet"
                                        style={{ textDecoration: "none", color: "#4F4F4F" }}
                                    >
                                        <ListItem
                                            sx={{
                                                backgroundColor:
                                                    window.location.pathname.split("/")[2] === "warmup_tamplet"
                                                        ? "#fff"
                                                        : "none",
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight:
                                                    window.location.pathname.split("/")[2] === "warmup_tamplet"
                                                        ? "4px solid #7953FF"
                                                        : "none",
                                                width: "100%",
                                                mb: 2,
                                            }}
                                        >
                                            <img src={commonMailLogo} alt="" style={{ marginRight: 10 }} />
                                            Warmup Template
                                        </ListItem>
                                    </NavLink>
                                }

                                {
                                    <NavLink
                                        exact
                                        to="subscriptions"
                                        style={{ textDecoration: "none", color: "#4F4F4F" }}
                                    >
                                        <ListItem
                                            sx={{
                                                backgroundColor:
                                                    window.location.pathname.split("/")[2] === "subscriptions"
                                                        ? "#fff"
                                                        : "none",
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight:
                                                    window.location.pathname.split("/")[2] === "subscriptions"
                                                        ? "4px solid #7953FF"
                                                        : "none",
                                                width: "100%",
                                                mb: 2,
                                            }}
                                        >
                                            <img src={subscriptionLogo} alt="" style={{ marginRight: 10 }} />
                                            Subscriptions
                                        </ListItem>
                                    </NavLink>
                                }
                            </List>
                        </Drawer>
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                height: "100vh",
                                overflow: "auto",
                            }}
                        >
                            <Container width="xl" sx={{ py: 2 }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    flexWrap="wrap"
                                >
                                    {window.location.pathname.split("/")[2] === "campaign" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Campaign
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "analytics" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Analytics
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "common_mailbox" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Common Mailbox
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "warmup_tamplet" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Warmup Template
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "email_accounts" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Email Accounts
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "deliverability" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Deliverability
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "integrations" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Integrations
                                        </Typography>
                                    )}
                                    {window.location.pathname.split("/")[2] === "subscriptions" && (
                                        <Typography
                                            color="#000"
                                            sx={{
                                                float: "left",
                                                fontSize: "24px",
                                                lineHeight: "36px",
                                                fontWeight: "700",
                                                fontStyle: "normal",
                                            }}
                                        >
                                            Subscriptions
                                        </Typography>
                                    )}

                                    <Box sx={{ marginLeft: "auto" }}>
                                        {/* <IconButton sx={{ width: "48px", height: "48px", mx: 1, border: "1px solid #e7e7e7", borderRadius: '6px', mb: 1 }}>
                                            <QuestionMarkOutlinedIcon />
                                        </IconButton>
                                        <IconButton sx={{ width: "48px", height: "48px", mx: 1, border: "1px solid #e7e7e7", borderRadius: '6px', mb: 1 }}>
                                            <Badge badgeContent={1} color="success" sx={{ }}>
                                                <NotificationsNoneOutlinedIcon />
                                            </Badge>
                                        </IconButton> */}

                                        {/* <FormControl sx={{ mb: 1 }}>
                                            <InputLabel id="demo-simple-select-autowidth-label">
                                                <Stack direction='row' alignItems='center' spacing={1} sx={{ fontSize: '14px' }}>
                                                    <span>Workspace</span>
                                                </Stack>
                                            </InputLabel>
                                            <StyledSelectOpt
                                                sx={{ height: "48px", width: "150px", border: "1px solid #e7e7e7", borderRadius: '6px', color: '#4F4F4F' }}
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                required
                                                value={selectedEmail}
                                                onChange={handleWorkSpaceChange}
                                                label="selectedEmail"
                                                // label={selectedEmail}
                                                autoWidth
                                            // input={}
                                            >
                                                {allWorkshops.map((obj) => (
                                                    <MenuItem
                                                        key={obj}
                                                        value={JSON.stringify(obj)}
                                                    >
                                                        {obj.name}
                                                    </MenuItem>
                                                ))}
                                            </StyledSelectOpt>
                                        </FormControl> */}

                                        <IconButton
                                            variant="outlined"
                                            onClick={handleProfileMenuOpen}
                                            sx={{
                                                mx: 1,
                                                mb: 1,
                                                padding: "8px",
                                                border: "1px solid #e7e7e7",
                                                borderRadius: "6px",
                                                color: "#4F4F4F",
                                                height: "48px",
                                            }}
                                        >
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <img
                                                    src={userProf}
                                                    alt="profile-img"
                                                    width="32px"
                                                    style={{ borderRadius: "50%" }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color: "#4F4F4F",
                                                        fontSize: "14px",
                                                        fontFamily: "'HK Nova', sans-serif",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {fname}
                                                </Typography>
                                                <KeyboardArrowDownIcon />
                                            </Stack>
                                        </IconButton>

                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={profileMenuOpen}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            open={Boolean(profileMenuOpen)}
                                            onClick={handleProfileMenuClose}
                                        >
                                            <MenuItem
                                                sx={{
                                                    borderBottom: "1px solid #E7E7E7",
                                                    "&:hover": {
                                                        borderLeft: "3px solid #7953FF",
                                                    },
                                                }}
                                            >
                                                <NavLink
                                                    to="setting"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#4F4F4F",
                                                        padding: "5px 5px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                        color="#7D7D7D"
                                                    >
                                                        <AccountCircleOutlinedIcon sx={{ fontSize: "20px" }} />{" "}
                                                        <span onClick={handleProfileMenuClose}>My Profile</span>
                                                    </Stack>
                                                </NavLink>
                                            </MenuItem>
                                            {/* <MenuItem
                        sx={{
                          borderBottom: "1px solid #E7E7E7",
                          "&:hover": {
                            borderLeft: "3px solid #7953FF",
                          },
                        }}
                      >
                        <NavLink
                          to="teams"
                          style={{
                            textDecoration: "none",
                            color: "#4F4F4F",
                            padding: "5px 5px",
                            fontSize: "12px",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            color="#7D7D7D"
                          >
                            <PeopleOutlineIcon sx={{ fontSize: "20px" }} />{" "}
                            <span onClick={handleProfileMenuClose}>Teams</span>
                          </Stack>
                        </NavLink>
                      </MenuItem> */}
                                            <MenuItem
                                                sx={{
                                                    borderBottom: "1px solid #f7f7f7",
                                                    "&:hover": {
                                                        borderLeft: "3px solid #7953FF",
                                                    },
                                                }}
                                            >
                                                <NavLink
                                                    to="subscriptions"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#4F4F4F",
                                                        padding: "5px 5px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                        color="#7D7D7D"
                                                    >
                                                        <PaymentsOutlinedIcon sx={{ fontSize: "20px" }} />{" "}
                                                        <span onClick={handleProfileMenuClose}>Subscription</span>
                                                    </Stack>
                                                </NavLink>
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    borderBottom: "1px solid #f7f7f7",
                                                    "&:hover": {
                                                        borderLeft: "3px solid #7953FF",
                                                    },
                                                }}
                                                onClick={handleLogoutAPI}
                                            >
                                                <NavLink
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#4F4F4F",
                                                        padding: "5px 5px",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                        color="#7D7D7D"
                                                    >
                                                        <LogoutOutlinedIcon sx={{ fontSize: "20px" }} />{" "}
                                                        <span>Logout</span>
                                                    </Stack>
                                                </NavLink>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Stack>
                                <Box sx={{ py: 6 }}>
                                    <Outlet />
                                    {/* <Routes>
                                        <Route path='campaign' element={<Campaign />} />
                                        <Route path='analytics' element={<Analytics />} />
                                        <Route path='email_accounts' element={<EmailAccounts />} />
                                        <Route path='integrations' element={<Integrations />} />
                                        <Route path="subscriptions" element={<Subscriptions />} />
                                        <Route path='common_mailbox' element={<CommonMailbox/>}/>
                                    </Routes> */}
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                    {/* <Snackbar open={successSnackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                        <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                            Login successful
                        </Alert>
                    </Snackbar> */}
                </>
            )}
        </ThemeProvider>
    );
};

export default DashBoard;
