import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const VerifyAccount = () => {
    const navigate = useNavigate();
    const [success, setSuccess] = React.useState(null);
    const inviteToken = window.location.pathname.split("/")[2];
    React.useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/accept_invite_workspace/${inviteToken}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    setSuccess(false);
                    return response.json();
                } else {
                    setSuccess(false);
                    return response.json();
                }
            })
            .then((data) => {
                if (data.status === 200) {
                    navigate("/login", { state: { invitationAccepted: success ? "success" : "error", msg: data.msg } });
                    return;
                } else if (data.status === 301) {
                    navigate("/register", { state: { accountVerified: success ? "success" : "error", msg: data.msg } });
                    return;
                } else {
                    navigate("/login", { state: { invitationAccepted: success ? "success" : "error", msg: data.msg } });
                    return;
                }
            })
            .catch((error) => console.log(error));
    }, []);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
            <CircularProgress />
        </Box>
    );
};

export default VerifyAccount;
